import * as React from 'react'
import * as Moment from 'moment'
import { from, t, accentUtils, showOK, toastInfo, goTo, goToNew, formHelper, showDialog, toastError, A, showWait, enqueue } from './HelperService'
import * as DataService from './DataService'
import * as ProductService from './ProductService'
import * as UserService from './UserService'
import * as ActivityService from './ActivityService'
import * as AppSettingsService  from './AppSettingsService'
import * as MessageService from './MessageService'
import { actionIDs } from './../controls/tracking/TrackingConstants'
import { showWizard, showWizardModel } from './../wizards/RTLWizard'
import { NewServiceLineWizard } from './../wizards/NewServiceLineWizard'
import RePriceJobWizard from './../wizards/RePriceJobWizard'
import EditJobWizard from './../wizards/EditJobWizard'
import ScheduleActivityWizard from './../wizards/ScheduleActivityWizard'
import MoveToDispatchedWizard from './../wizards/MoveToDispatchedWizard'



import { CalendarCheckMeasureCtrl } from '../controls/calendar/CalendarCheckMeasureCtrl'
import { CalendarInstallCtrl } from '../controls/calendar/CalendarInstallCtrl'

import { OpenServiceJobActivityDlg } from './../dialogs/OpenServiceJobActivityDlg'
import { UpdateCommitReasonDlg } from './../dialogs/UpdateCommitReasonDlg'
import { BulkUpdateCommissionDateDlg } from './../dialogs/BulkUpdateCommissionDateDlg'


import { MFReleaseDlg } from './../dialogs/MFReleaseDlg'
import { ByPassCMCtrl, ByPassCMNoPermissionCtrl } from '../controls/ByPassCMCtrl'
import { EmailAccountDetailsCtrl } from '../controls/EmailAccountDetailsCtrl'
import { InvoiceParamsCtrl, QuoteParamsCtrl } from '../controls/documents/DocumentParams'
import { isMobileOnly } from 'react-device-detect'
import { EmailDlg, mapToBaseEmailViewModel } from '../dialogs/EmailDlg'
import { CalendarServiceCtrl } from '../controls/calendar/CalendarServiceCtrl'
import { SortJobLineDlg } from '../dialogs/SortJobLineDlg'
import { JobPriceDetailsDlg } from '../dialogs/JobPriceDetailsDlg'
import { JobCostSummaryDlg } from '../dialogs/JobCostSummaryDlg'
import { MoveToSupplyDlg } from '../dialogs/MoveToSupplyDlg'
import { FinishEditJobDlg } from '../dialogs/FinishEditJobDlg'
import { PrintDlg } from '../dialogs/PrintDlg'
import { BulkChangeDlg } from '../dialogs/BulkChangeDlg'
import { SelectLinesToInvoiceDlg } from '../dialogs/SelectLinesToInvoiceDlg'
import { SignatureDlg } from '../dialogs/SignatureDlg'
import { scheduleActivityTypes } from './helpers/FormHelper'
import { RestockLineDlg } from '../dialogs/RestockLineDlg'
import JobOnHoldErrorDlg from '../dialogs/JobOnHoldErrorDlg'
import { JobOnHoldDlg } from '../dialogs/JobOnHoldDlg'
import { CMCompleteDlg } from '../dialogs/CMCompleteDlg'





const query_InvoiceEmailDetails = DataService.queryNoTracking("InvoiceEmailDetails");
const query_OrderPreviewDetails = DataService.queryNoTracking("OrderPreviewDetails");
const query_JobInstallPreviewDetails = DataService.queryNoTracking("JobInstallPreviewDetails");
const query_Product_FindAlterProductID = DataService.queryNoTracking("Product_FindAlterProductID");
const query_ServiceJob_AllowConfirm = DataService.queryNoTracking("ServiceJob_AllowConfirm");
const query_Tracking_EditJobWizard = DataService.queryNoTracking("Tracking_EditJobWizard");
const query_Tracking_GetFinishEditJobWizard = DataService.queryNoTracking("Tracking_GetFinishEditJobWizard");
const query_AddressForJobID = DataService.queryNoTracking("AddressForJobID");
const query_Activity_GetLinesToBook = DataService.queryNoTracking("Activity_GetLinesToBook", undefined, true);
const query_Job_GetBulkUpdateViewModel = DataService.queryNoTracking("Job_GetBulkUpdateViewModel");
const query_OrderEmailDetails = DataService.queryNoTracking("OrderEmailDetails");
const query_QuoteEmailDetails = DataService.queryNoTracking("QuoteEmailDetails");
const query_Job_HasValidationErrors = DataService.queryNoTracking("Job_HasValidationErrors");
const query_GetAI_Credits = DataService.queryNoTracking("GetAI_Credits", undefined, false, undefined, { allowCaching: false });



const toFixedPicklist = (picklist) => {
    picklist.values = Object.keys(picklist).map(k=> picklist[k]);
    picklist.getPicklistSource = () => picklist.values.map(v => { return { ID: v, Description: t(v) }; });
};


export const JobReminders = {
    Quote_Expire: 1,
    CM_NotBooked: 2,
    CM_NotConfirmed: 3,
    CM_NotCompleted: 4,
    Stock_Due_X: 5,
    Stock_NotArrived: 6,
    Inst_NotBooked_AfterSock: 7,
    Inst_NotConfirmed: 8,
    Inst_NotCompleted: 9,
    Pymt_NoDeposit: 10,
    Pymt_Outstanding: 11,
    Pymt_Credit: 12,
};

export const apiContextEmailTypes = {
    Quote: "edit_email_type_quote",
    JobContact: "edit_email_type_job_contact",
    getPromptValue: e => {
        if (e === apiContextEmailTypes.Quote) {
            return "Sales Quote";
        }

        return "Job Contact";
    }
};

export const apiScopes = {
    Read: "READ",
    ReadWrite: "READ-WRITE",
};
toFixedPicklist(apiScopes);



export const jobCheckMeasureStatus = {

    None: null,
    InProgress: "job_cm_in_progress",
    AwaitingApproval: "job_cm_awaiting_approval",    
};

toFixedPicklist(jobCheckMeasureStatus);

export const taxLookupTypes = {

    Sales: "tax_lookup_type_sales",
    Purchasing: "tax_lookup_type_purchasing",
};

toFixedPicklist(taxLookupTypes);


export const jobLineTypes = {

    New: "type_job_line_new",
    Remake: "type_job_line_remake",
    Alteration: "type_job_line_alter",
    Service: "type_job_line_service",
};

toFixedPicklist(jobLineTypes);


export const jobTypes = {
    Sales: "type_job_sales",
    Service: "type_job_service",
};
toFixedPicklist(jobTypes);

export const jobStages = {
    Quote: "stage_job_quote",
    Order: "stage_job_order",
    Lost: "stage_job_lost",
    OrderEdit: "stage_job_order_edit"
};
toFixedPicklist(jobStages);


export const jobStatus = {
    Open: "status_job_open",
    Closed: "status_job_closed",
    Cancelled: "status_job_cancelled"
};
toFixedPicklist(jobStatus);

// must match CreditCardProviders in RTL
export const creditCardProviders = {
    VisaCredit: "Visa Credit",
    VisaDebit: "Visa Debit",
    MastercardCredit: "Mastercard Credit",
    MastercardDebit: "Mastercard Debit",
    AmericanExpress: "American Express",
    DinersClubCredit: "Diners Club Credit",
    DinersClubDebit: "Diners Club Debit",
    DiscoverCredit: "Discover Credit",
    DiscoverDebit: "Discover Debit",
    JCBCredit: "JCB Credit",
    JCBDebit: "JCB Debit",
    UnionPayCredit: "China Union Pay Credit",
    UnionPayDebit: "China Union Pay Debit"
};
toFixedPicklist(creditCardProviders);



export const preferredContactMethods = {
    Email: "contact_method_email",
    Mobile: "contact_method_mobile",
};
toFixedPicklist(preferredContactMethods);




export const jobLineDispatchStages = {
    InstallNotBooked: "dispatch_job_line_install_not_booked",
    InstallBooked: "dispatch_job_line_install_booked",

    PickupNotBooked: "dispatch_job_line_pickup_not_booked",
    PickupBooked: "dispatch_job_line_pickup_booked",

    DeliveryNotBooked: "dispatch_job_line_delivery_not_booked",
    DeliveryBooked: "dispatch_job_line_delivery_booked",

    Installed: "dispatch_job_line_installed",
    PickedUp: "dispatch_job_line_picked_up",
    Delivered: "dispatch_job_line_delivered",

    PendingService: "dispatch_job_line_pending_service",
};

toFixedPicklist(jobLineDispatchStages);

export const jobLineStages = {

    Unconfirmed: "stage_job_line_unconfirmed",
    Unconfirmed_Sent: "stage_job_line_unconfirmed_sent",
    Unconfirmed_CM: "stage_job_line_unconfirmed_cm",
    CMNotBooked: "stage_job_line_cm_not_booked",
    CMBooked: "stage_job_line_cm_booked",
    CMInProgress: "stage_job_line_cm_inprogress",
    CMCompleted: "stage_job_line_cm_completed",
    CMCompleted_Unconfirmed: "stage_job_line_cm_completed_unconfirmed",
    CMCompleted_Confirmed: "stage_job_line_cm_completed_confirmed",
    CMCompleted_Reschedule: "stage_job_line_cm_completed_reschedule",
    CMCompleted_Confirmed_Reschedule: "stage_job_line_cm_completed_confirmed_reschedule",
    Confirmed: "stage_job_line_confirmed",
    Confirmed_CM: "stage_job_line_confirmed_cm",
    Supply: "stage_job_line_supply",
    SupplyPending: "stage_job_line_supply_pending",
    SupplyFailed: "stage_job_line_supply_failed",
    Dispatch: "stage_job_line_dispatch",
    Invoicing: "stage_job_line_invoicing",
    Restocked: "stage_job_line_restocked",
    RestockedConsumed: "stage_job_line_restocked_consumed",
    RestockedDiscarded: "stage_job_line_restocked_discarded",
};

toFixedPicklist(jobLineStages);


export const jobLineStatus = {

    Open: "status_job_line_open",
    Closed: "status_job_line_closed",
    Edit: "status_job_line_edit",
    OnHold: "status_job_line_on_hold",
    Cancelled: "status_job_line_cancelled",   
};

toFixedPicklist(jobLineStatus);


export const automationTypes = {
    Email: "Email",
    SMS: "SMS",
    EmailAndSMS: "Email and SMS"
};
toFixedPicklist(automationTypes);

export const automationSendTo = {

    Contact: "Contact",
    SiteContact: "Site Contact"

};

toFixedPicklist(automationSendTo);

export const automationTimeframes = {

    DaysPriorToAppt: "Days prior to the appointment date",
    HoursAfterAppt: "Hours after the appointment is created",
    HoursPriorAppt: "Hours prior to the appointment date",
    DaysAfterJobComplete: "Days after job completed",
    HoursAfterReschedule: "Hours after the appointment is rescheduled"

};
toFixedPicklist(automationTimeframes);



export const automationAppointmentTypes = {
    Any: "Any",
    SalesAppointment: "Sales Appointment",
    ShowroomQuote: "Showroom Quote",
    CalendarNote: "Calendar Note",
    CheckMeasure: "Check Measure",
    DispatchAppointment: "Dispatch Appointment",
    Rework: "Rework",
    Service: "Service",
    Task: "Task",
    Warranty: "Warranty"
};

toFixedPicklist(automationAppointmentTypes);


export const automationDispatchTypes = {
    Any: "Any",
    Install: "Install",
    Delivery: "Delivery",
    Pickup: "Pick-up"
};
toFixedPicklist(automationDispatchTypes);

export const automationJobTypes = {
    Any: "Any",
    Sales: "Sales",
    Service: "Service"
}
toFixedPicklist(automationJobTypes);

export const balanceOutstandingSelections = {
    Yes: "Yes",
    YesOnActivity: "Yes - On activity",
    NoOnActivity: "No - On activity",
    No: "No",
    Any: "Any",
}
toFixedPicklist(balanceOutstandingSelections);

export const triStateSelections = {
    Yes: "Yes",
    No: "No",
    Any: "Any"
};
toFixedPicklist(triStateSelections);


export const statementTypes = {
    Activity: "stm_type_activity",
    Outstanding: "stm_type_outstanding"
};
toFixedPicklist(statementTypes);

export const accountTypes = {
    Retail: "acc_type_retail",
    Wholesale: "acc_type_wholesale",
    Commercial: "acc_type_commercial"
};
toFixedPicklist(accountTypes);



export const opporunityStage = {
    Lead: "stage_lead_lead",
    Negotiation: "stage_lead_negotiation",
    Quotation: "stage_lead_quotation",
    Contract: "stage_lead_contract",
};
toFixedPicklist(opporunityStage);


export const opporunityStatus = {

    Open: "status_lead_open",
    Abandoned: "status_lead_abandoned",
    Lost: "status_lead_lost",
    Won: "status_lead_won",

};
toFixedPicklist(opporunityStatus);


export const businessUnitRules = {
    SalesRep: "sales_rep",
    CurrentUser: "current_user",
    Area: "business_unit_area"
};
toFixedPicklist(businessUnitRules);

export const paymentTerms = {
    PaymentOnDelivery: "pt_job_pod",
    PaymentPriorToDelivery: "pt_job_ppd",
    InvoiceTerms: "pt_job_invoice_terms",
    AccountCustomer: "pt_job_account_customer"
};
toFixedPicklist(paymentTerms);

export const paymentTermsDueValues = {
    InvoiceTerms: "pt_job_invoice_terms",
    AccountCustomer: "pt_job_account_customer"
};
toFixedPicklist(paymentTermsDueValues);


export const paymentDue = {
    InAGivenNoOfDays: "pd_no_of_days",
    OnADayOfTheMonth: "pd_eom",
    NoOfDaysAfterEOM: "pd_days_after_eom",
    DayOfMonthAfterEOM: "pd_day_of_month_after_eom",
}
toFixedPicklist(paymentDue);


export const viewDataRules = {
    AllData: "rep_view_data_all",
    TeamsData: "rep_view_data_teams",
    OwnData: "rep_view_data_own"
};
toFixedPicklist(viewDataRules);

export const retailSignatureRules = {
    NoWarning: "retail_job_signature_rule_no_warning",
    Warning: "retail_job_signature_rule_warning",
    Required: "retail_job_signature_rule_required"
};
toFixedPicklist(retailSignatureRules);


export const manageRosterRules = {
    All: "rep_manage_roster_all",
    Teams: "rep_manage_roster_teams",
    Own: "rep_manage_roster_own",
    None: "rep_manage_roster_none"
};
toFixedPicklist(manageRosterRules);


export const templateResponseConditions = {
    StartsWith: "Starts With",
    EndsWith: "Ends With",
    Contains: "Contains",
    NQE: "Does Not Contain",
    EQ: "Equals",
    Other: "Other",
    NoResponse: "No Response"
}
toFixedPicklist(templateResponseConditions);

export const templateResponseActions = {
    Confirmed: "Confirm Appointment",
    UnConfirmed: "Unconfirm Appointment",
    Nothing: "Nothing",   
}
toFixedPicklist(templateResponseActions);

export const templateResponseTagActions = {
    AddTags: "Add Tags",
    RemoveTags: "Remove Tags",
}
toFixedPicklist(templateResponseTagActions);


viewDataRules.min = function (r1, r2) {

    var indexR1 = viewDataRules.values.indexOf(r1);
    var indexR2 = viewDataRules.values.indexOf(r2);

    if (indexR1 >= indexR2) {
        return r1;
    } else {
        return r2;
    }
};
viewDataRules.gte = function (r1, r2) {

    var indexR1 = viewDataRules.values.indexOf(r1);
    var indexR2 = viewDataRules.values.indexOf(r2);

    return (indexR1 <= indexR2);
};

export const PaymentTypes = {
    Payment: "type_payment_payment",
    Refund: "type_payment_refund",
    Credit: "type_payment_credit",
    Transfer: "type_payment_transfer"
};
toFixedPicklist(PaymentTypes);


export const paymentStatuses = {
    Draft: "status_payment_draft",
    Confirmed: "status_payment_confirmed",
    Cancelled: "status_payment_cancelled",
    Finalised: "status_payment_finalised"
};
toFixedPicklist(paymentStatuses);


export const commissionTransTypes = {
    Standard: "type_standard",
    Amendment: "type_amendment"
};
toFixedPicklist(commissionTransTypes);

export const commissionTransStatuses = {
    Draft: "status_draft",
    Paid: "status_paid"
};
toFixedPicklist(commissionTransStatuses);

export const communicationTypes = {
    Email: "comm_type_email",
    SMS: "comm_type_sms",
    SMS_Reply: "comm_type_sms_reply"
};
toFixedPicklist(communicationTypes);

export const supplyLineStatus = {
    Open: "status_po_line_open",
    Sent: "status_po_line_sent",
    PartialDelivered: "status_po_line_partial_delivered",
    Delivered: "status_po_line_delivered",
    Closed: "status_po_line_closed",
    Cancelled: "status_po_line_cancelled",

    Released: "status_po_line_released",
    InProgress: "status_po_line_inprogress",
    Completed: "status_po_line_completed",


    Priced: "status_po_line_priced",
    Received: "status_po_line_received",
    Manufacturing: "status_po_line_manufacturing",
    Dispatch: "status_po_line_dispatch",
    Invoiced: "status_po_line_invoiced"
};
toFixedPicklist(supplyLineStatus);


export const fileNoteTypes = {
    General: "type_file_note_general",
    Install: "type_file_note_install"
};
toFixedPicklist(fileNoteTypes);



export function replaceI18nKeysInHtmlString(htmlString) {

    if (accentUtils.isEmpty(htmlString)) return htmlString;

    // Create a regular expression to find i18n keys
    const i18nKeyPattern = /application_strings\.application\.[a-zA-Z0-9_.-]+/g;

    // Find all i18n keys in the HTML string
    const i18nKeys = htmlString.match(i18nKeyPattern) || [];

    // Replace each i18n key with its translation
    i18nKeys.forEach((key) => {
        const translation = t(key);
        htmlString = htmlString.replace(key, translation);
    });

    return htmlString;
}

export const Helper = {

    Initialize: function () {
        MessageService.Helper.register('JobService', MessageService.MessageTypes.Tracking, Helper.notifyTracking);
    },

    margin2Markup: function (margin) {

        if (margin === 100) {
            return 0;
        }

        return accentUtils.round(margin / (1 - (margin / 100)));
    },
    markup2Margin: function (markup) {

        if (markup === -100) {
            return 0;
        }

        return accentUtils.round(markup / (1 + (markup / 100)));
    },


    

    clearForProduct: function (line, product) {

        //line.ProductID = accentUtils.getEmptyGuid();

        line["ProductName"] = null;
        line["ProductCode"] = null;
        line["ProductVersion"] = null;
        line["ValidationErrors"] = null;
        line["OrderLineOptionsData"] = null;
        line["IsService"] = false;

        line.Price = 0;
        line.PriceTax = 0;
        line.PriceIncTax = 0;
        line.SalePrice = 0;
        line.SalePriceTax = 0;
        line.SalePriceIncTax = 0;
        line.BasePrice = 0;
        line.BasePriceDiscount = 0;
        line.BasePriceAdjustment = 0;
        line.BaseCost = 0;
        line.BaseCostDiscount = 0;
        line.BaseCostAdjustment = 0;

        line.InstallPrice = 0;
        line.InstallPriceDiscount = 0;
        line.InstallPriceAdjustment = 0;
        line.InstallCost = 0;
        line.InstallCostDiscount = 0;
        line.InstallCostAdjustment = 0;
        line.OptionsPrice = 0;
        line.OptionsCost = 0;

        line.DeliveryPrice = 0;
        line.DeliveryPriceDiscount = 0;
        line.DeliveryPriceAdjustment = 0;
        line.DeliveryCost = 0;
        line.DeliveryCostDiscount = 0;
        line.DeliveryCostAdjustment = 0;

        line.AdjustmentPrice = 0;
        line.AdjustmentCost = 0;


        line.CostPrice = 0;
        line.CostPriceTax = 0;
        line.CostPriceIncTax = 0;

        line.OriginalCostPrice = 0;
        line.OriginalCostPriceTax = 0;
        line.OriginalCostPriceIncTax = 0;
        line.PriceUnit22 = 0;

        if (accentUtils.isNull(product)) return;

        //TODO
        if (product.IsMultiConfig) return; 
            
        if (product.IsMarketplaceProduct || !accentUtils.isEmpty(product.ModelURI)) {
            Helper.updateProductDetailsMP(line, product);
        } else {
            Helper.updateProductDetailsMyProduct(line, product);
        }
        


    },

    updateProductDetailsMP: function (line, product, multiProduct) {

        

        line["ProductName"] = product.Product ?? product.ProductName;
        line["ProductCode"] = product.ProductCode;
        line["ProductVersion"] = product.Version;

        line["IsService"] = product?.isServiceProduct;

        if (multiProduct?.IsMultiConfig ?? false) {
            line["MultiProductID"] = multiProduct.ID;
            line["MultiProductName"] = multiProduct.ProductName;
            line["MultiProductCode"] = multiProduct.ProductCode;
        } else {
            line["MultiProductID"] = null;
            line["MultiProductName"] = null;
            line["MultiProductCode"] = null;
        }

        line["ProductID"] = product.ID;

        if (accentUtils.isEmpty(line.SupplierID)) {
            line.SupplierID = product.SupplierKey;
        }
        if (accentUtils.isEmpty(line.ProductVersionID)) {
            line.ProductVersionID = product.VersionID;
        }


    },
    updateProductDetailsMyProduct: function (line, product) {

        line["ProductID"] = product.ID;
        line["ValidationErrors"] = null;
        line["OrderLineOptionsData"] = null;

        line["ProductName"] = product.Product ?? product.ProductName;
        line["ProductCode"] = product.ProductCode;
        line["ProductVersion"] = null;
        line["MultiProductID"] = null;
        line["MultiGroupID"] = null;
        line["MultiProductName"] = null;
        line["MultiProductCode"] = null;

        line["IsService"] = product?.isServiceProduct ?? false;


        if (accentUtils.isEmpty(line.SupplierID)) {
            line.SupplierID = product.SupplierKey;
        }

        if (accentUtils.isEmpty(line.ProductVersionID)) {
            line.ProductVersionID = product.VersionID;
        }

    },


    hasEntityContext: function (context) {
        return !accentUtils.isNull(context) && context.id > 0;
    },

    cancellationWizardComplete: function (model) {

        return new Promise(function (p) {

            DataService.update("CancellationWizardComplete", model).then(res => {
                p(res);
            });

        });

    },
  

    notifyTracking: function (msgArg) {


        const msg = { ...msgArg, Prompt: replaceI18nKeysInHtmlString(msgArg.Prompt) }

        const linkStyle = { color: "white" };

        const user = UserService.getUser();

        if (msg.RepID === user.ID) {


            if (msg.ConfirmJobValidation && !msg.InValidStage) {
                toastError(<A style={linkStyle} href={`/Job/${msg.JobID}`}>{t("application_strings.toasts.confirmJobValidationInvalidStage", msg)}</A>)
                return;
            }

            if (msg.ConfirmJobValidation) {
                toastError(<A style={linkStyle} href={`/Job/${msg.JobID}`}>{t("application_strings.toasts.confirmJobValidationErrors", msg)}</A>)
                return;
            }


            if (msg.ActivityMissingSkill) {
                toastError(<A style={linkStyle} href={`/Activity/${msg.ActivityID}`}>{t("application_strings.toasts.activityMissingSkill", msg)}</A>)
                return;
            }

            if (msg.Invoice) {
                if (msg.Failed) {
                    toastError(<A style={linkStyle} href={`/Job/${msg.JobID}`}>{t("application_strings.toasts.invoiceCreateFailed", msg)}</A>)
                } else {
                    toastInfo(<A style={linkStyle} href={`/Job/${msg.JobID}`}>{t("application_strings.toasts.invoiceCreated", msg)}</A>)
                }
                
            } else if (msg.Supply) {

                if (msg.Failed) {
                    toastError(<A style={linkStyle} href={`/Job/${msg.JobID}`}>{t("application_strings.toasts.supplyJobCreateFailed", msg)}</A>)
                } else {
                    toastInfo(<A style={linkStyle} href={`/Job/${msg.JobID}`}>{t("application_strings.toasts.supplyJobCreated", msg)}</A>)
                }

                
            }
        }
    },


    getAIQueryRemainingCredits: async function () {

        const result = query_GetAI_Credits.getFirstOrDefault();


        return result;
    },

    //INVOICES

    processInvoiceEmailTempate: function (template, data) {
        console.log('processing invoice email template');
        console.log(data);


        var res = {
            Subject: template.Subject,
            Body: template.Body
        };

        var exps = [
            {
                exp: /\{\{Invoice.Rep\}\}/g,
                val: data.User.FullName
            },
            {
                exp: /\{\{Contact.FirstName\}\}/g,
                val: data.Contact.FirstName
            },
            {
                exp: /\{\{Invoice.Number\}\}/g,
                val: data.Invoice.InvoiceNo
            }
        ];

        exps.forEach(function (e) {

            var val = e.val;

            if (accentUtils.isNull(val)) {
                val = "";
            }

            res.Subject = accentUtils.isEmpty(res.Subject) ? '' : res.Subject.replace(e.exp, val);
            res.Body = accentUtils.isEmpty(res.Body) ? '' : res.Body.replace(e.exp, val);
        });


        return res;
    },


    showEmailInvoiceWizard: function (invoiceID) {


        return new Promise(function (p) {

            query_InvoiceEmailDetails.getFirstOrDefault({ id: invoiceID }).then(function (info) {

                var invoiceDoc = info.Attachments[0];



                invoiceDoc.HeaderItem = <InvoiceParamsCtrl model={info} />;


                showDialog(<EmailDlg
                    title="application_strings.application.general.emailInvoice"
                    emailTemplateAppSettingsField="DefaultInvoiceEmailTemplate"
                    templates={info.Templates}
                    to={info.To}
                    attachments={info.Attachments}
                    onSend={e => {
                        return new Promise(resolveSent => {

                            const quoteDocID = `INVOICE:${invoiceID}`;

                            const model = mapToBaseEmailViewModel(e, info.ID, info.Data.Contact.FullName, {}, quoteDocID);

                            Helper.emailInvoice(invoiceID, model).then(sr => Helper.processEmailSendResult(resolveSent, sr));
                        });
                    }}
                />).then(r => {
                    p();
                });



            });
        });
    },


    emailInvoice: function (id, email) {
        return DataService.updateAsFormData("EmailInvoice", { ...email, ID: id });
    },

    unconfirmPayment: async function (id) {
        const res = await DataService.update("UnconfirmPayment", { ID: id });

        if (!res.Success) {
            await Helper.showUnconfirmPaymentError(res.Error);
        }

        return res;
    },
    confirmPayment: async function (id, paymentDate) {

        


            if (new Date(paymentDate) > new Date()) {

                await Helper.showConfirmFuturePaymentError();

                return false;
            }

            return await DataService.update("ConfirmPayment", { ID: id });

    },

    async showConfirmFuturePaymentError() {

        await showOK("Future Payment", "Confirming a future payments is not permitted", ["application_strings.application.buttons.ok"], false, null, true, 600);

    },
    async showUnconfirmPaymentError(error) {

        await showOK("Unconfirm Payment Error", t(error), ["application_strings.application.buttons.ok"], false, null, true, 600);

    },

 prepareMerchantPayment: function (id) {

        return new Promise(function (p) {

            DataService.update("PrepareMerchantPayment", { ID: id }).then(res => {
                p(res);
            });

        });

    },

    processMerchantTerminalClosedEvent: async function (guid, id) {

        return await DataService.update("ProcessMerchantTerminalClosedEvent", guid, id);

    },
    newCreditNoteWizard: function (model) {
        return DataService.update("NewCreditNoteWizard", model);
    },

    cancelInvoice: function (id, jobID) {

        return new Promise(function (p) {
            DataService.update("CancelInvoice", { ID: id, ParentID: jobID }).then(res => p(res));
        });

    },

    reopenInvoice: function (id) {
        return new Promise(function (p) {
            DataService.update("ReOpenInvoice", { ID: id }).then(res => p(res));
        });
    },


    //REMINDERS

    isReminderAllowed: function (tracking, reminderID) {

        if (accentUtils.isNull(tracking.DismissedReminders)) {
            return true;
        }

        var dismissList = tracking.DismissedReminders;

        return !from(dismissList).any(r => r.Type === reminderID && (accentUtils.isNull(r.Expiry) || new Date(r.Expiry) >= accentUtils.today()));
    },


    dismissReminder: function (jobID, isStandard, reminderID) {

        return DataService.update("DismissReminder", {
            JobID: jobID,
            IsStandard: isStandard,
            ReminderID: reminderID
        });

    },

    snoozeReminder: function (jobID, isStandard, reminderID, days) {

        var date = new Date();
        date.setDate(date.getDate() + days);


        return DataService.update("SnoozeReminder", {
            JobID: jobID,
            IsStandard: isStandard,
            ReminderID: reminderID,
            Date: new Date(date).toISOString()
        });

    },

    //OPPORTUNITY -------------------------

    canEditOpportunity: function (opp) {
        return (opp.Status === opporunityStatus.Open);
    },

    canScheduleOpportunityAppointment: function (opp) {
        return (opp.Status === opporunityStatus.Open);
    },

    canMarkOpportunityAsAbandond: function (opp) {
        return (opp.Status === opporunityStatus.Open);
    },

    pricePurchaseOrder: function (id) {

        var done = showWait();

        return new Promise(function (p) {
            DataService.update("PricePurchaseOrder", { ID: id }).then(res => {
                p(res);
                done();
            });

        });
    },


    //PURCHASE ORDER -------------------------
    canAdjustPurchaseOrderCosts: function (po) {
        return po.Status !== "status_po_open" && po.Status !== "status_po_priced" && po.Status !== "status_po_cancelled";
    },

    canReOpenPurchaseOrder: function (po) {
        return po.Status === "status_po_priced";
    },
    canPricePurchaseOrder: function (po) {
        return po.Status === "status_po_open";
    },


    canPrintPurchaseOrder: function (job) {
        return UserService.getAccess().features().FeatureAllowSendOrder() && (job.Status !== "status_po_open") && UserService.getAccess().modules().RTL_Supply();
    },

    isInvoiceFinanceLocked(invoice) {
        const user = UserService.getUser();

        const lockDate = user.DefaultSettings?.FinanceLockDate;

        return !accentUtils.isNull(lockDate) && new Date(lockDate) >= new Date(invoice.InvoiceDate);

    },

    canEditInvoice: function (invoice) {

        const invoiceLocked = Helper.isInvoiceFinanceLocked(invoice);

        var canEdit = !invoiceLocked && ( (invoice.Status === "status_invoice_open") && UserService.getAccess().features().FeatureCanViewFinance());

        const closedStatus = invoice.Status === "status_invoice_closed" || invoice.Status === "status_invoice_cancelled";

        var isClosed = closedStatus || invoiceLocked;

        const reason = !closedStatus && isClosed ? "Locked" : invoice.Status;

        return Helper.getReadOnlyResult(!canEdit, reason, isClosed);
    },

    canOpenInvoice: function (invoice) {

        const invoiceLocked = Helper.isInvoiceFinanceLocked(invoice);


        if (invoiceLocked) return false;

        return (invoice.Status !== "status_invoice_open" && invoice.Status != "status_invoice_cancelled") && UserService.getAccess().features().FeatureCanViewFinance();
    },


    canEditPurchaseOrder: function (order) {
        var canEdit = (order.Status === "status_po_open");

        var isClosed = order.Status === "status_po_cancelled" || order.Status === "status_po_closed";

        return Helper.getReadOnlyResult(!canEdit, order.Status, isClosed);
    },
    canEditOrderLine: function (line) {
        var canEdit = (line.Status === "status_po_line_open");

        var isClosed = line.Status === "status_po_line_closed" || line.Status === "status_po_line_cancelled";

        return Helper.getReadOnlyResult(!canEdit, line.Status, isClosed);
    },

    canSendPurchaseOrder: function (order, accentUserModel) {
        return UserService.getAccess().features().FeatureAllowSendOrder() && (order.Status === "status_po_priced") && UserService.getAccess().modules().RTL_Supply();
    },

    canCancelPurchaseOrder: function (order) {
        return order.Status !== "status_po_cancelled";
    },

    canClosePurchaseOrder(order) {
        return UserService.getAccess().features().FeatureAllowReceiptGoods() && (order.Status !== "status_po_cancelled" && order.Status !== "status_po_open" && order.Status !== "status_po_priced");
    },

    cancelPurchaseOrder: function (id, jobID) {

        return new Promise(function (p) {
            DataService.update("CancelPurchaseOrder", { ID: id, ParentID: jobID }).then(res => p(res));
        });

    },


    undoCancelPurchaseOrder: function (id) {

        return new Promise(function (p) {
            DataService.update("UndoCancelPurchaseOrder", { ID: id }).then(res => p(res));
        });

    },

    reOpenPurchaseOrder: function (id) {

        return new Promise(function (p) {
            DataService.update("ReOpenPurchaseOrder", { ID: id }).then(res => p(res));
        });

    },


    //JOB -------------------------

    downloadJobSupplySheets(id) {

        return new Promise(function (p) {

            DataService.update("DownloadJobSupplySheets", { IDs: [id] }).then(r => {


                r.Files.map(f => {


                    const url = accentUtils.createBlob(f.File);

                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    a.href = url;
                    a.download = f.Name;
                    a.click();
                    URL.revokeObjectURL(url);



                });

                p();

                if (!accentUtils.isEmpty(r.Errors)) {
                    showOK("Some Errors Occurred", r.Errors, ["application_strings.application.buttons.close"], true);
                }


            });


        });


    },
    downloadSupplyJobSupplySheets(ids, lineIDs, isPreview) {

        const delay = 2000;
        return new Promise(function (p) {

            

            


            showDialog(<MFReleaseDlg isPreview={isPreview} />)
                .then(s => {

                    const ref = { current: null };
                    const done = showWait(ref);

                    if (!s.canceled) {

                        DataService.update("DownloadSupplyJobSupplySheets", { IDs: ids, UpdateStatus: s.updateStatus, XML: s.xml, XLS: s.xls, LineIDs: lineIDs, IsPreview: isPreview }, false).then(res => {

                            const fileCount = from(res).selectMany(r => r.Files).count();

                            const noFiles = fileCount === 0;

                            if (noFiles) {

                                done();
                                showOK("No Supply Sheets found", "No supply sheets found", ["application_strings.application.buttons.close"], true, null, false, 900).then(x => {
                                    p();
                                });                                                            
                            }else {


                                let count = 1;
                                

                                console.log("File Count:", fileCount);

                                res.map(r => {

                                    r.Files.map(f => {



                                        enqueue(() => {

                                            return new Promise(dp => {

                                                const url = accentUtils.createBlob(f.File);

                                                var a = document.createElement('a');
                                                document.body.appendChild(a);
                                                a.style = 'display: none';
                                                a.href = url;
                                                a.download = f.Name;
                                                a.click();

                                                ref.current?.updateSubMessage(`downloading ${count} of ${fileCount}`);

                                                count++;

                                                setTimeout(() => {
                                                    
                                                    URL.revokeObjectURL(url);
                                                    dp();
                                                }, delay);


                                            });

                                            


                                        });

                                        



                                    });



                                    if (!accentUtils.isEmpty(r.Errors)) {


                                        enqueue(() => {

                                            return new Promise(ep => {

                                                var eb = new Blob(r.Errors, { type: 'html/text' });

                                                var eurl = window.URL.createObjectURL(eb);

                                                var ea = document.createElement('a');
                                                document.body.appendChild(ea);
                                                ea.style = 'display: none';
                                                ea.href = eurl;
                                                ea.download = `${r.OrderNumber}.txt`;
                                                ea.click();

                                                ref.current?.updateSubMessage(`downloading ${count} of ${fileCount}`);

                                                count++;
                                                setTimeout(() => {

                                                    URL.revokeObjectURL(eurl);
                                                    ep();
                                                }, delay);

                                                
                                            });
                                        });
                                    }
                                });


                                enqueue(async () => done());


                                enqueue(async () => {

                                    var supplyJobsWithNoFiles = from(res).where(r => r.Files.length === 0).select(r => r.OrderNumber).distinct().toArray();


                                    if (supplyJobsWithNoFiles.length === 0) return;

                                    const message = supplyJobsWithNoFiles.join('\n');

                                    await showOK("No Supply Sheets found for...", message, ["application_strings.application.buttons.close"], true, null, false, 900)


                                });


                                enqueue(async ()=> p());
                            }
                            });
                    





                    } else {

                        done();
                        p();
                    }

                });


        });


    },
    jobIsInCheckMeasureRequiredStage(job, jobLines) {

        return job.Stage === 'stage_job_order' && from(jobLines ?? job.JobLines).any(l => Helper.jobLineIsCheckMeasureRequiredStage(l));
    },

    jobIsInCheckMeasure(job) {

        const ff = 44;
        const gg = ff;

        return job.CheckMeasureStatus === jobCheckMeasureStatus.AwaitingApproval || job.CheckMeasureStatus === jobCheckMeasureStatus.InProgress; 

        
    },


    jobIsInCheckMeasureInProgress(job) {
        return job.CheckMeasureStatus === jobCheckMeasureStatus.InProgress;        
    },
    jobIsInCheckMeasureAproval(job) {
        return job.CheckMeasureStatus === jobCheckMeasureStatus.AwaitingApproval;        
    },

    jobLineIsInCheckMeasure(line) {
        return Helper.jobLineIsInCheckMeasureInProgress(line) || Helper.jobLineIsInCheckMeasureAproval(line)
    },

    jobLineIsInCheckMeasureInProgress(line) {
        return line.Stage === 'stage_job_line_cm_inprogress' || line.Stage === 'stage_job_line_unconfirmed_cm' || line.Stage === 'stage_job_line_confirmed_cm';
    },

    jobLineIsInCheckMeasureAproval(line) {
        return line.Stage === 'stage_job_line_cm_completed' || line.Stage === 'stage_job_line_cm_completed_confirmed' || line.Stage === 'stage_job_line_cm_completed_unconfirmed' || line.Stage === 'stage_job_line_cm_completed_reschedule' || line.Stage === 'stage_job_line_cm_completed_confirmed_reschedule';
    },

    jobLineIsCheckMeasureConfirmed(line) {
        return line.Stage === 'stage_job_line_cm_inprogress' || line.Stage === 'stage_job_line_cm_completed' || line.Stage === 'stage_job_line_confirmed_cm' || line.Stage === 'stage_job_line_cm_completed_confirmed' || line.Stage === 'stage_job_line_cm_completed_reschedule' || line.Stage === 'stage_job_line_cm_completed_confirmed_reschedule';
    },
    jobLineIsCheckMeasureUnConfirmed(line) {
        return line.Stage === 'stage_job_line_unconfirmed_cm' || line.Stage === 'stage_job_line_cm_completed_unconfirmed';
    },

    jobLineIsCheckMeasureRequiredStage(line) {
        return line.Stage === 'stage_job_line_cm_not_booked' || line.Stage === 'stage_job_line_cm_booked';
    },

    jobHasConfirmableLines(job) {
        return from(job.JobLines).any(l => !Helper.jobLineIsConfirmed(l) || Helper.jobLineIsCheckMeasureUnConfirmed(l));
    },


    jobLineAllowCreateRemake(line) {

        return line.Stage === jobLineStages.Invoicing
            || line.Stage === jobLineStages.Dispatch
            || line.Stage === jobLineStages.Restocked
            || line.Stage === jobLineStages.RestockedConsumed
            || line.Stage === jobLineStages.RestockedDiscarded;            

    },

    jobLineAllowCreateAlter(line) {

        return line.Stage === jobLineStages.Invoicing
            || line.Stage === jobLineStages.Dispatch;

    },


    jobLineAllowUnConfirm(job, line) {

        const isService = line.IsService;

        if (!isService) {

            if (line.DispatchStage === jobLineDispatchStages.PendingService) return false;
            if (line.Stage === jobLineStages.Dispatch) return false;
            if (line.Stage === jobLineStages.Invoicing) return false;
            if (line.Stage === jobLineStages.Restocked) return false;
            if (line.Stage === jobLineStages.RestockedConsumed) return false;

        }


        var hasOtherLines = from(job.JobLines.filter(l => l.ID != line.ID)).any(l => Helper.jobLineIsConfirmed(l) && l.Stage !== jobLineStages.Unconfirmed_CM);

        if (!hasOtherLines) return false;


        var isCM = Helper.jobIsInCheckMeasure(job);

        return (
            (
                isCM && Helper.jobLineIsCheckMeasureConfirmed(line) && ((Helper.jobLineIsInCheckMeasureInProgress(line) && UserService.getAccess().features().FeatureAllowPerformCheckMeasure()) || (Helper.jobLineIsInCheckMeasureAproval(line) && UserService.getAccess().features().FeatureAllowApproveCheckMeasure()))
            )
            ||
            (
                !isCM && Helper.jobLineIsConfirmed(line)
            )
        );
    },


  

    jobLineAllowRestock(job, line) {

        return line.Qty === 1
            && !line.IsService
            && (
                line.Stage === jobLineStages.Dispatch
                || line.Stage === jobLineStages.Invoicing
                || (line.Stage === jobLineStages.RestockedConsumed && !line.RestockConsumed)
        );

    },

    jobLineAllowUndoRestock(job, line) {

        return line.Qty === 1
            && !line.IsService
            && line.Stage === jobLineStages.Restocked
            && !line.RestockConsumed;

    },


    requireCMCompletion(job) {
        return Helper.jobIsInCheckMeasureInProgress(job) && UserService.getAccess().features().FeatureAllowPerformCheckMeasure();
    },



    requireCMApproval(job) {
        if (UserService.getAccess().features().FeatureAllowApproveCheckMeasure()) {
            return Helper.jobIsInCheckMeasureAproval(job);
        }
        return false;
    },

    showJobLineSortWizard(jobID) {
        return showDialog(<SortJobLineDlg jobID={jobID} />);
    },

    async restockLine(id) {

        var res = await showDialog(<RestockLineDlg  />)

        if (res.canceled) return null;

        var updateResult = await DataService.update("RestockLine", { ID: id, LocationID: res.LocationID, Notes: res.Notes });

        return updateResult;
    },
            
    async undoRestockLine(id) {

        
        var updateResult = await DataService.update("UndoRestockLine", { ID: id  });

        return updateResult;
    },




    canViewEstimatedCommissions(saleRepID) {

        const user = UserService.getUser();

        var isSalesRep = user.ID === saleRepID;
        return UserService.getAccess().modules().RTL_Commissions() && (UserService.getAccess().features().FeatureAllowViewEstimatedCommissions() || isSalesRep)
    },
    canViewCommissionsGrid(saleRepID) {
        const user = UserService.getUser();

        var isSalesRep = user.ID === saleRepID;
        return UserService.getAccess().modules().RTL_Commissions() && (UserService.getAccess().features().FeatureAllowRunCommissions() || isSalesRep)
    },
    copyJobLineAsService(sourceLine, destinationLine, defaultProductID) {


        if (!accentUtils.isNull(sourceLine)) {
            destinationLine.LocationOther = sourceLine.LocationOther;
        } else {
            destinationLine.ProductID = defaultProductID;

            const product = ProductService.getProduct(defaultProductID);

            if (product.IsMultiConfig) {


                Helper.convertLineFromMultiToSingle(destinationLine);
                destinationLine.MultiProductID = defaultProductID;
                destinationLine.ProductID = null;
            }

        }

        destinationLine.LineType = jobLineTypes.Service;


         

        return Promise.resolve(destinationLine);
    },
    copyJobLineAsAlter(sourceLine, destinationLine, defaultProductID) {

        if (!accentUtils.isNull(sourceLine)) {
            destinationLine.LocationOther = sourceLine.LocationOther;


            destinationLine.Price = 0;
            destinationLine.PriceTax = 0;
            destinationLine.PriceIncTax = 0;
            destinationLine.SalePrice = 0;
            destinationLine.SalePriceTax = 0;
            destinationLine.SalePriceIncTax = 0;
            destinationLine.OriginalCostPrice = 0;
            destinationLine.OriginalCostPriceTax = 0;
            destinationLine.OriginalCostPriceIncTax = 0;
            destinationLine.CostPrice = 0;
            destinationLine.CostPriceTax = 0;
            destinationLine.CostPriceIncTax = 0;


        }

        destinationLine.LineType = jobLineTypes.Alteration;


        return new Promise(p => {

            const alterProductID = accentUtils.isNull(sourceLine) ? Promise.resolve({ AlterProductID: defaultProductID }) : query_Product_FindAlterProductID.getFirstOrDefault({ id: sourceLine.ProductID });

            alterProductID.then(ap => {

                destinationLine.ProductID = ap.AlterProductID;

                p(destinationLine);

            });


        });



    },


    convertLineFromMultiToSingle(line) {

        line.MultiConfigID = null;
        line.MultiProductID = null;
        line.MultiGroupID = null;
    },


    async copyJobLineAsRemake(sourceLine, destinationLine, defaultProductID) {





        if (!accentUtils.isNull(sourceLine)) {
            await Helper.copyJobLineFrom(sourceLine, destinationLine);
        } else {
            destinationLine.ProductID = defaultProductID;
        }

        destinationLine.LineType = jobLineTypes.Remake;


        Helper.convertLineFromMultiToSingle(destinationLine);

        return destinationLine;
    },

    async copyJobLineFrom(sourceLine, destinationLine) {


        destinationLine.LocationOther = sourceLine.LocationOther;
        destinationLine.Width = sourceLine.Width;
        destinationLine.WidthMeasurements = sourceLine.WidthMeasurements;
        destinationLine.Drop = sourceLine.Drop;
        destinationLine.DropMeasurements = sourceLine.DropMeasurements;
        destinationLine.Qty = sourceLine.Qty;
        destinationLine.LineType = sourceLine.LineType;
        destinationLine.Price = sourceLine.Price;
        destinationLine.PriceTax = sourceLine.PriceTax;
        destinationLine.PriceIncTax = sourceLine.PriceIncTax;
        destinationLine.SalePrice = sourceLine.SalePrice;
        destinationLine.SalePriceTax = sourceLine.SalePriceTax;
        destinationLine.SalePriceIncTax = sourceLine.SalePriceIncTax;
        destinationLine.OriginalCostPrice = sourceLine.OriginalCostPrice;
        destinationLine.OriginalCostPriceTax = sourceLine.OriginalCostPriceTax;
        destinationLine.OriginalCostPriceIncTax = sourceLine.OriginalCostPriceIncTax;
        destinationLine.CostPrice = sourceLine.CostPrice;
        destinationLine.CostPriceTax = sourceLine.CostPriceTax;
        destinationLine.CostPriceIncTax = sourceLine.CostPriceIncTax;
        destinationLine.DisplayOption1 = sourceLine.DisplayOption1;
        destinationLine.DisplayOption2 = sourceLine.DisplayOption2;
        destinationLine.DisplayOption3 = sourceLine.DisplayOption3;
        destinationLine.DisplayOption4 = sourceLine.DisplayOption4;
        destinationLine.DisplayOption5 = sourceLine.DisplayOption5;
        destinationLine.DisplayOption6 = sourceLine.DisplayOption6;
        destinationLine.DisplayOption7 = sourceLine.DisplayOption7;
        destinationLine.DisplayOption8 = sourceLine.DisplayOption8;
        destinationLine.DisplayOption9 = sourceLine.DisplayOption9;
        destinationLine.DisplayOption10 = sourceLine.DisplayOption10;
        destinationLine.ProductID = sourceLine.ProductID;
        destinationLine.SupplierID = sourceLine.SupplierID;
        destinationLine.SupplierName = sourceLine.SupplierName;
        destinationLine.ProductName = sourceLine.ProductName;
        destinationLine.ProductCode = sourceLine.ProductCode;
        destinationLine.Notes = sourceLine.Notes;
        destinationLine.SupplyNotes = sourceLine.SupplyNotes;
        destinationLine.OrderLineOptionsData = sourceLine.OrderLineOptionsData;
        destinationLine.RequireCheckMeasure = sourceLine.RequireCheckMeasure;
        destinationLine.RequireInstall = sourceLine.RequireInstall;
        destinationLine.RequireDelivery = sourceLine.RequireDelivery;
        destinationLine.RequirePickup = sourceLine.RequirePickup;
        destinationLine.MultiGroupID = null;
        destinationLine.MultiProductID = sourceLine.MultiProductID;
        destinationLine.MultiConfigID = sourceLine.MultiConfigID;
        destinationLine.MultiSizeWidthLinked = sourceLine.MultiSizeWidthLinked;
        destinationLine.MultiSizeHeightLinked = sourceLine.MultiSizeHeightLinked;
        destinationLine.MultiProductName = sourceLine.MultiProductName;
        destinationLine.MultiProductCode = sourceLine.MultiProductCode;
        destinationLine.IsService = sourceLine.IsService;
        destinationLine.UserSetAsIncTax = sourceLine.UserSetAsIncTax;

        const sourceLineServiceInfo = sourceLine.JobLineService;
        const sourceHasService = !accentUtils.isNull(sourceLineServiceInfo);

        const newJobLineService = (sourceLine.LineType === jobLineTypes.New || !sourceHasService) ? null : await DataService.addEntity("JobLineService");

        if (!accentUtils.isNull(newJobLineService)) {
            Helper.copyJobLineServiceFrom(sourceLineServiceInfo, newJobLineService);
            destinationLine.JobLineService = newJobLineService;
        }




    },

    copyJobLineServiceFrom(source, destination) {

        destination.JobID = source.JobID;
        destination.JobLineID = source.JobLineID;
        destination.ChargeAmount = source.ChargeAmount;
        destination.ReasonNotes = source.ReasonNotes;
        destination.UserAtFaultID = source.UserAtFaultID;
        destination.SupplierAtFaultID = source.SupplierAtFaultID;
        destination.PL_FaultReasonID = source.PL_FaultReasonID;


    },


    showNewServiceLineWizard(newLine, hideService) {

        return new Promise(p => {

            showWizard(NewServiceLineWizard, true, { line: newLine, hideService: hideService }, false, 400).then(function (res) {


                p(res);
            });
        });
    },


    showJobPriceDetails(jobID, lines) {
        showDialog(<JobPriceDetailsDlg jobID={jobID} lines={lines} />);
    },

    showJobCostSummary(jobID, jobReference, canViewCommissions, lines) {
        showDialog(<JobCostSummaryDlg jobID={jobID} jobReference={jobReference} canViewCommissions={canViewCommissions} lines={lines} />);
    },


    rePriceJob(job) {

        return new Promise(function (p) {

            showWizard(RePriceJobWizard, true, { job: job }).then(function (e) {
                if (e) {
                    //goTo("/Job/" + job.ID);
                    p();
                }
            });
        });
    },


    printDocument(title, query, paramsCtrl) {
        return showDialog(<PrintDlg
            query={query}
            title={title}
            paramsCtrl={paramsCtrl}
        />);
    },

    selectLinesForInvoice(jobID, defaultLinefilter){

        return showDialog(<SelectLinesToInvoiceDlg jobID={jobID} defaultLinefilter={defaultLinefilter} />);

    },

    printOrder(jobID) {

        return Helper.printDocument(
            "Print Order",
            () => query_OrderPreviewDetails.getFirstOrDefault({ id: jobID }),
            <QuoteParamsCtrl type="OrderConfirmation" />
        ).then(res => {

            if (!res.canceled) {

                const doc = res.document;

                if (!accentUtils.isNull(doc)) {

                    var id = JSON.stringify({
                        ID: jobID,
                        Params: doc.Params,
                        MarkAsSent: false
                    });

                    DataService.download(`/Download/PrintOrder/${id}`);
                }

            }

        });

    },


    processInstall(jobID, lineFilter) {

        if (lineFilter?.length > 0) {
            goTo(`/InstallCompletionForm/${jobID}?lines=${lineFilter}`);
        } else {
            goTo(`/InstallCompletionForm/${jobID}`);
        }

        

    },

    printInstallJob(jobID) {

        return Helper.printDocument(
            "Print Install Job",
            () => query_JobInstallPreviewDetails.getFirstOrDefault({ id: jobID }),
            <QuoteParamsCtrl type="Installer" />)
            .then(res => {

                if (!res.canceled) {

                    const doc = res.document;

                    if (!accentUtils.isNull(doc)) {

                        var id = JSON.stringify({
                            ID: jobID,
                            Params: doc.Params,
                            MarkAsSent: false
                        });

                        DataService.download(`/Download/PrintInstallJob/${id}`);
                    }

                }

                
            });

    },

    showServiceJobUnresolvedActivities(jobID, rootPromise) {

        query_ServiceJob_AllowConfirm.getAll({ jobID: jobID }).then(r => {

            if (r.length === 0) {
                rootPromise(true);
            } else {

                OpenServiceJobActivityDlg.Show(jobID, r, rootPromise);
            }

        });


    },



    async startEditJob(id, cb) {
        const res = await DataService.update("StartEditJob", { ID: id });

        goTo("/Job/" + id);
        if (cb !== undefined) {
            cb();
        }

    },

    editJob(id) {

        var done = showWait();

        return new Promise(function (p) {

            query_Tracking_EditJobWizard.getFirstOrDefault({ jobID: id }).then(function (res) {

                if ((res.SupplyInfo?.length ?? 0) !== 0) {

                    showWizard(EditJobWizard, true, { jobID: id, editModel: res }).then(function (e) {

                        if (!e.canceled) {
                            Helper.startEditJob(id, done)
                        } else {
                            done();
                        }

                        p();
                    });

                    return;
                } else {

                    Helper.startEditJob(id, done);
                    p();
                }

            });


        });

    },

    cancelEditJobWizard: function (model) {
        return new Promise(function (p) {
            DataService.update("CancelEditJobWizard", model).then(res => p(res));
        });
    },


    completeEditJobWizard: function (jobID) {
        return new Promise(function (p) {
            DataService.update("CompleteEditJob", { ID: jobID }).then(res => p(res));
        });
    },



    loadFinishEditJobData(id) {

        return query_Tracking_GetFinishEditJobWizard.getFirstOrDefault({
            jobID: id,
        });

    },


    

    async finishEditJob(id, isCMFinish) {

        const validationWarning = await query_Job_HasValidationErrors.getFirstOrDefault({ jobID: id });

        if (validationWarning.HasValidationError) {

            const btn = await showOK("application_strings.application.dialogs.confirmCompleteFinishEditTitle", "application_strings.application.dialogs.confirmCompleteFinishEditMsg", ["application_strings.application.buttons.no", "application_strings.application.buttons.yes"], false, null, true, 500);

            if (btn !== "application_strings.application.buttons.yes") {
                return;
            }

        }

        let resheduledLineIDs = null;

        if (isCMFinish) {

            const completeResult = await showDialog(<CMCompleteDlg jobID={id} />);

            if (completeResult.canceled) return;


            resheduledLineIDs = completeResult.rescheduled;
        }


        var done = showWait();

        const showWizard = await DataService.update("StartFinishEditJob", { ID: id, RescheduleIDs: resheduledLineIDs });


        if (showWizard) {
            const wizModel = await Helper.loadFinishEditJobData(id);

            const e = await showDialog(<FinishEditJobDlg jobID={id} editModel={wizModel} />)

            if (e.canceled) {
                const result = await DataService.update("CancelFinishEditJob", { ID: id });
            }
        }

        goTo("/Job/" + id);
        done();
    },

    bookService(job, subject, filter, addr, duration, requiredProducts) {

        
        var ctrlLineFilter = filter?.JobLineIDs;

        var serviceData = {
            scheduleJobModel: formHelper.getCalendarScheduleJobModel(
                scheduleActivityTypes.service,
                job.BusinessUnitID,
                t("application_strings.views.job.scheduleServiceMessage", { order: job.Reference }),
                function (appt) {

                    ActivityService.Helper.defaultContactDetails(appt, job);

                    appt.OpportunityID = job.OpportunityID;
                    appt.JobID = job.ID;
                    appt.Subject = subject;
                    appt.ActivityType = ActivityService.Helper.typeService;
                    appt.TagData = accentUtils.isNull(filter) ? null : JSON.stringify(filter);
                    ActivityService.Helper.copyAddress(appt, addr);
                },
                React.createElement(CalendarServiceCtrl, { id: job.ID, lineFilter: ctrlLineFilter }),
                accentUtils.isEmpty(ctrlLineFilter),
                addr.AreaID,
                requiredProducts
            )

        };


        goTo("/Calendar", serviceData);
    },

    bookCheckMeasure(job, subject, filter, addr, duration, requiredProducts) {


        var ctrlLineFilter = filter?.JobLineIDs;

        var checkMeasureData = {
            scheduleJobModel: formHelper.getCalendarScheduleJobModel(
                scheduleActivityTypes.cm,
                job.BusinessUnitID,
                t("application_strings.views.job.scheduleCheckMeasureMessage", { order: job.Reference }),
                Helper.getBookCheckMeasureOnNewFunction(job, subject, filter, addr, duration),
                React.createElement(CalendarCheckMeasureCtrl, { id: job.ID, lineFilter: ctrlLineFilter }),
                accentUtils.isEmpty(ctrlLineFilter),
                addr.AreaID,
                requiredProducts
            )

        };


        goTo("/Calendar", checkMeasureData);
    },


    bookAppointment(job, lineIDFilter = null, book, type) {
        var data = [];

        var done = showWait();

        if (accentUtils.isNull(job.Address)) {
            data.push(query_AddressForJobID.getFirstOrDefault({ jobID: job.ID }));
        } else {
            data.push(Promise.resolve(job.Address));
        }


        data.push(query_Activity_GetLinesToBook.getFirstOrDefault({
            JobID: job.ID,
            ActivityType: type,
            LineIDs: lineIDFilter
        }));


        Promise.all(data).then(r => {

            var info = r[1]


            var promise = null;

            var validation = Helper.getBookAppointmentValidation(info);

            if (!accentUtils.isNull(validation)) {
                promise = showWizard(ScheduleActivityWizard, true, { data: info, validation: validation }, true);

            } else {
                promise = Promise.resolve({ lineIDs: from(info.Items).select(i => i.ID).distinct().toArray() });
            }


            promise.then(o => {

                if (!accentUtils.isNull(o) && !o.canceled) {
                    
                    var durationByLine = from(info.Items).where(r => from(o.lineIDs).any(x => x === r.ID)).select(v => v.EstInstallTime).sum();
                    var duration = durationByLine;
                    const filter = { JobLineIDs: o.lineIDs };

                    const requiredProducts = r[1]?.Items?.map(i => ({ productID: i.ProductID, description: i.ProductName }));

                    book(job, info.ActivitySubject, filter, r[0], duration, requiredProducts);
                }
                done()
            }).catch(function () { done(); });


        }).catch(function () { done(); });


    },


    getBookAppointmentValidation(info) {

        var result = {};
        var isValid = true;
        info.Items.map(l => {

            const isCM = info.ActivityType === ActivityService.Helper.typeCheckMeasure;
            const isInst = info.ActivityType === ActivityService.Helper.typeInstall;
            const isService = info.ActivityType === ActivityService.Helper.typeService;

            const isRequired = isInst || (isCM && l.RequireCheckMeasure);
            const pendingService = l.DispatchStage === jobLineDispatchStages.PendingService;
            const warnCMNotRequired = isCM && (l.CM_Approved || (l.Stage !== jobLineStages.CMNotBooked))
            const warnCMExists = isCM && !l.CM_Approved && l.Stage != jobLineStages.CMNotBooked && from(info.ExistingLineActivities).any(la => la.JobLineID === l.ID && la.ActivityType === ActivityService.Helper.typeCheckMeasure)
            const warnCMForSupplyItem = isCM && (l.Stage === jobLineStages.SupplyFailed || l.Stage === jobLineStages.SupplyPending || l.Stage === jobLineStages.Supply);
            const warnCMReceipted = isCM && (l.Stage === jobLineStages.Dispatch || l.Stage === jobLineStages.Invoicing);
            const warnInstExists = isInst && (l.DispatchStage !== jobLineDispatchStages.PickupNotBooked && l.DispatchStage !== jobLineDispatchStages.InstallNotBooked && l.DispatchStage !== jobLineDispatchStages.DeliveryNotBooked && l.DispatchStage !== jobLineDispatchStages.PendingService);
            const CMUnconfirmed = (l.Stage === jobLineStages.Unconfirmed_CM) || (l.Stage === jobLineStages.CMCompleted_Unconfirmed);
            const hasRestockItem = (l.Stage === jobLineStages.Restocked) || (l.Stage === jobLineStages.RestockedConsumed) || (l.Stage === jobLineStages.RestockedDiscarded); 

            const warnHasFutureOpenAppointmentTypes = !isService ? [] : from(info.FutureOpenActivityTypes)
                .where(t => t === ActivityService.Helper.typeSales ||
                    t === ActivityService.Helper.typeInstall ||
                    t === ActivityService.Helper.typeService ||
                    t === ActivityService.Helper.typeCheckMeasure ||
                    t === ActivityService.Helper.typeWarranty ||
                    t === ActivityService.Helper.typeRework ||
                    t === ActivityService.Helper.typeShowroomQuote ||
                    t === ActivityService.Helper.typeTask)
                .select(txt => t(txt)).toArray();

            


            result[l.ID] = {
                isRequired: isRequired,
                isPendingService: isInst && pendingService,
                hasExistingCM: isCM && warnCMExists,
                hasExistingInst: isInst && warnInstExists,
                warnHasSupplyItem: warnCMForSupplyItem,
                warnHasBeenReceipted: warnCMReceipted,
                isCmUnconfirmed: CMUnconfirmed,
                hasRestockItem: hasRestockItem,
                warnCMNotRequired: warnCMNotRequired,
                warnHasFutureOpenAppointmentTypes: warnHasFutureOpenAppointmentTypes
            };

            result[l.ID].isValid = result[l.ID].isRequired && !result[l.ID].isPendingService && !result[l.ID].hasExistingCM && !result[l.ID].hasExistingInst && !result[l.ID].warnHasSupplyItem && !result[l.ID].warnHasBeenReceipted && !result[l.ID].isCmUnconfirmed && !result[l.ID].hasRestockItem && result[l.ID].warnHasFutureOpenAppointmentTypes.length === 0 && !result[l.ID].warnCMNotRequired;


            if (isValid) {
                isValid = result[l.ID].isValid;
            }

        });


        if (!isValid)
            return result;

        return null;

    },


    getLinesToSchedule(jobID, type) {
        return new Promise(p => {

            query_Activity_GetLinesToBook.getFirstOrDefault({ JobID: jobID, ActivityType: type, LineIDs: null })
                .then(info => {

                    var promise = null;


                    var validation = Helper.getBookAppointmentValidation(info);

                    if (!accentUtils.isNull(validation)) {
                        promise = showWizard(ScheduleActivityWizard, true, { data: info, validation: validation });
                    } else {
                        promise = Promise.resolve({ lineIDs: from(info.Items).select(i => i.ID).distinct().toArray() });
                    }


                    promise.then(wizResult => {

                        if (!accentUtils.isNull(wizResult)) {
                            p({ canceled: false, lineIDs: wizResult.lineIDs });
                        } else {
                            p({ canceled: true, lineIDs: [] })
                        }
                    });

                });


        });
    },

    validateCanCopyLine(line) {

        var hasServiceLineInfo = !accentUtils.isNull(line.JobLineService) || !accentUtils.isNull(line.JobLineServiceID);
        var hasLinkedLine = hasServiceLineInfo && !accentUtils.isNull(line.JobLineService) && (!accentUtils.isNull(line.JobLineService.JobLine) || !accentUtils.isNull(line.JobLineService.JobLineID));


        if (hasServiceLineInfo && accentUtils.isNull(line.JobLineService)) {

            alert("DEBUG: Require Service Info");
            return Promise.resolve(false);
        }



        if (!hasLinkedLine) {
            return Promise.resolve(true);
        }

        return new Promise(p => {
            showOK("application_strings.application.dialogs.jobLineValidForCopyTitle", "application_strings.application.dialogs.jobLineValidForCopyMsg", ["application_strings.application.buttons.ok"], false, null, true).then(r => {
                p(false);
            });
        });

    },


    byPassCMForJobs(ids) {
        return DataService.update("ByPassCMForJobs", { IDs: ids });
    },


    byPassCMForActivities(ids) {
        return DataService.update("ByPassCMForActivities", { IDs: ids });
    },

    showByPassCMWarning() {


        const userCanApprove = UserService.getAccess().features().FeatureAllowApproveCheckMeasure();

        if (userCanApprove) {
            return new Promise(p => {
                showOK("application_strings.views.calendar.byPassCMTitle", React.createElement(ByPassCMCtrl), ["application_strings.views.calendar.byPassCMButton"]).then(r => {
                    p(r === "application_strings.views.calendar.byPassCMButton");
                });
            });
        } else {
            return new Promise(p => {
                showOK("application_strings.views.calendar.byPassCMTitle", React.createElement(ByPassCMNoPermissionCtrl), ["application_strings.application.buttons.ok"], false, undefined, true, 600).then(r => {
                    p(false);
                });
            });
        }

    },

    bookServiceWithMaps(jobIDs, groupKey, defaultBUs) {
        goTo(`/MapScheduler?type=Service&ids=${jobIDs}&groupKey=${groupKey}&bu=${defaultBUs}`);
    },

    bookInstallWithMaps(jobIDs, groupKey, defaultBUs) {
        goTo(`/MapScheduler?type=Install&ids=${jobIDs}&groupKey=${groupKey}&bu=${defaultBUs}`);
    },

    bookCheckMeasureWithMaps(jobIDs, groupKey, defaultBUs) {
        goTo(`/MapScheduler?type=CM&ids=${jobIDs}&groupKey=${groupKey}&bu=${defaultBUs}`);
    },


    getBookCheckMeasureOnNewFunction(job, subject, filter, addr, duration) {
        return function (appt) {

            ActivityService.Helper.defaultContactDetails(appt, job);


            if (!accentUtils.isEmpty(duration)) {

                var currDuration = Moment.duration(new Moment(appt.End).diff(appt.Start)).asMinutes();

                if (currDuration === 30 && duration > currDuration) {
                    appt.End = new Moment(appt.Start).add(duration, "minutes").toDate();
                }
            }

            appt.OpportunityID = job.OpportunityID;
            appt.JobID = job.ID;
            appt.Subject = subject;
            appt.ActivityType = ActivityService.Helper.typeCheckMeasure;
            appt.TagData = accentUtils.isNull(filter) ? null : JSON.stringify(filter);
            ActivityService.Helper.copyAddress(appt, addr);
        }

    },

    getBookInstallOnNewFunction(job, subject, filter, addr, duration) {
        return function (appt) {

            var currDuration = Moment.duration(new Moment(appt.End).diff(appt.Start)).asMinutes();

            if (currDuration === 30 && duration > currDuration) {
                appt.End = new Moment(appt.Start).add(duration, "minutes").toDate();
            }

            ActivityService.Helper.defaultContactDetails(appt, job);

            appt.OpportunityID = job.OpportunityID;
            appt.JobID = job.ID;
            appt.Subject = subject;
            appt.ActivityType = ActivityService.Helper.typeInstall;
            appt.TagData = accentUtils.isNull(filter) ? null : JSON.stringify(filter);
            ActivityService.Helper.copyAddress(appt, addr);

        };
    },

    bookInstall(job, subject, filter, addr, duration, requiredProducts) {

        var ctrlLineFilter = filter?.JobLineIDs;


        var installData = {
            scheduleJobModel: formHelper.getCalendarScheduleJobModel(
                scheduleActivityTypes.install,
                job.BusinessUnitID,
                t("application_strings.views.job.scheduleInstallMessage", { order: job.Reference }),
                Helper.getBookInstallOnNewFunction(job, subject, filter, addr, duration),
                React.createElement(CalendarInstallCtrl, { id: job.ID, lineFilter: ctrlLineFilter }),
                accentUtils.isEmpty(ctrlLineFilter),
                addr.AreaID,
                requiredProducts
            )

        };


        goTo("/Calendar", installData);

    },

    jobGetTrackingActions(job, lineIDs) {

        var res = [];

        if (!Helper.canCancelJob(job)) return [];


        if (Helper.canMakePayment(job)) {
            res.push(actionIDs.newPayment);
        }


        if (Helper.canBookService(job)) {
            res.push(actionIDs.bookService);
            if (!isMobileOnly) {
                res.push(actionIDs.bookServiceMaps);
            }
        }


        if (Helper.canBookCM(job)) {
            res.push(actionIDs.bookCM);

            if (!isMobileOnly)
                res.push(actionIDs.bookCMMaps);
        }

        if (Helper.canBookInst(job)) {
            res.push(actionIDs.bookInst);

            if (!isMobileOnly)
                res.push(actionIDs.bookInstMaps);
        }

        if (Helper.canInvoiceJob(job)) {

            if (res.length > 0) {
                res.push("divider");
            }

            res.push(actionIDs.invoiceJob);
            res.push(actionIDs.invoiceJobGenerate);


            res.push("divider");
        }


        if (Helper.canMoveToSupply(job)) {
            res.push(actionIDs.moveToSupply);
        }



        if (Helper.canUpdateETA(job)) {
            res.push(actionIDs.updateETA);
        }


        if (Helper.canReceiptGoods(job)) {
            res.push(actionIDs.goodsReceived);
        }

        if (Helper.canMarkAsDispatched(job)) {
            res.push(actionIDs.markAsDispatched);
        }


        res.push("divider");

        if (Helper.jobRequiresEdit(job)) {
            res.push(actionIDs.editJob);
        }
        if (Helper.jobRequiresFinishEdit(job)) {
            res.push(actionIDs.finishEditJob);
        }

        if (Helper.allowSendOrderConfirmation(job)) {
            res.push(actionIDs.sendOrderConfirmation);
            res.push(actionIDs.printOrderConfirmation);
            res.push(actionIDs.printInstallJobSheet);
            res.push(actionIDs.processInstall);
        }

        res.push("divider");


        if (!Helper.jobIsClosed(job) && !Helper.jobIsInEdit(job)) {
            res.push(actionIDs.closeJob);
        }

        if (Helper.canCancelJob(job)) {
            res.push(actionIDs.cancelJob);
        }
        return res;
    },

    jobProcessTrackingActionMulti(type, items, args) {

        if (type === actionIDs.moveToSupply) {
            return Helper.jobMoveToSupply(items);
        } else if (type === actionIDs.goodsReceived) {
            return Helper.jobReceiptGoods(items);
        } else if (type === actionIDs.closeJob) {
            return Helper.jobClose(items);
        } else if (type === actionIDs.invoiceJob) {
            return Helper.invoiceJobs(items, args);
        } else if (type === actionIDs.invoiceJobGenerate) {
            return Helper.invoiceJobs(items, args);
        } else if (type === actionIDs.alterTags) {
            return Helper.alterJobTags(items, args);
        } else if (type === actionIDs.updateCommitReason) {
            return Helper.setCommitmentReason(items, args);
        } else if (type === actionIDs.updateCommissionDate) {
            return Helper.setCommissionDate(items, args);
        } else if (type === actionIDs.updateETA) {
            return Helper.jobUpdateETA(items.map(j => { return { ...j, ETA: args }; }));
        } else if (type === actionIDs.bookServiceMaps) {
            Helper.bookServiceWithMaps(items.map(j => j.ID), null, from(items).select(j => j.BusinessUnitID).distinct().toArray());
            return Promise.resolve();
        } else if (type === actionIDs.bookCMMaps) {
            Helper.bookCheckMeasureWithMaps(items.map(j => j.ID), null, from(items).select(j => j.BusinessUnitID).distinct().toArray());
            return Promise.resolve();
        } else if (type === actionIDs.bookInstMaps) {
            Helper.bookInstallWithMaps(items.map(j => j.ID), null, from(items).select(j => j.BusinessUnitID).distinct().toArray());
            return Promise.resolve();
        } else if (type === actionIDs.markAsDispatched) {
            return Helper.markAsDispatched(items, null);
        }
    },


    jobProcessTrackingAction(type, job, lineIDs, args) {


        if (type === actionIDs.moveToSupply || type === actionIDs.resendToSupply) {
            return Helper.jobMoveToSupply([{ ID: job.ID, LineIDs: lineIDs }]);
        } else if (type === actionIDs.invoiceJob) {
            return Helper.invoiceJobs([{ ID: job.ID, LineIDs: lineIDs }], args);
        } else if (type === actionIDs.invoiceJobGenerate) {
            return Helper.invoiceJobs([{ ID: job.ID, LineIDs: lineIDs }], args);
        } else if (type === actionIDs.goodsReceived) {
            return Helper.jobReceiptGoods([{ ID: job.ID, LineIDs: lineIDs }]);
        } else if (type === actionIDs.cancelJob) {
            return Helper.jobCancel(job.ID);
        } else if (type === actionIDs.closeJob) {
            return Helper.jobClose([{ ID: job.ID, LineIDs: lineIDs }]);
        } else if (type === actionIDs.markAsDispatched) {
            return Helper.markAsDispatched([{ ID: job.ID, LineIDs: lineIDs }]);
        } else if (type === actionIDs.bookService) {

            window.setTimeout(function () {
                Helper.bookAppointment(job, lineIDs, Helper.bookService, ActivityService.Helper.typeService);
            }, 100);

            return Promise.resolve();
        } else if (type === actionIDs.bookServiceMaps) {
            Helper.bookServiceWithMaps([job.ID], lineIDs, [job.BusinessUnitID]);
            return Promise.resolve();

        } else if (type === actionIDs.bookCM) {

            window.setTimeout(function () {                
                Helper.bookAppointment(job, lineIDs, Helper.bookCheckMeasure, ActivityService.Helper.typeCheckMeasure);
            }, 100);

            return Promise.resolve();
        } else if (type === actionIDs.bookCMMaps) {
            Helper.bookCheckMeasureWithMaps([job.ID], lineIDs, [job.BusinessUnitID]);
            return Promise.resolve();
        } else if (type === actionIDs.bookInst) {

            window.setTimeout(function () {
                
                Helper.bookAppointment(job, lineIDs, Helper.bookInstall, ActivityService.Helper.typeInstall);
            }, 100);


            return Promise.resolve();
        } else if (type === actionIDs.bookInstMaps) {
            Helper.bookInstallWithMaps([job.ID], lineIDs, [job.BusinessUnitID]);
            return Promise.resolve();
        } else if (type === actionIDs.newPayment) {

            window.setTimeout(function () {
                goToNew("/Payment","Payment", { ContactID: job.ContactID }, null, null, { jobID: job.ID });
            }, 100);

            return Promise.resolve();
        } else if (type === actionIDs.updateETA) {
            return Helper.jobUpdateETA([{ ID: job.ID, LineIDs: lineIDs, ETA: args }]);
        }
    },

    jobUpdateETA(jobIDs) {
        return DataService.update("UpdateJobsETA", {
            Jobs: jobIDs
        });
    },


    showBulkUpdateWizard: function (jobID) {


        return new Promise(function (p) {

            query_Job_GetBulkUpdateViewModel.getFirstOrDefault({ jobID: jobID }).then(function (info) {

                showDialog(<BulkChangeDlg data={info} />).then(r => {

                    p();

                });
                //showWizardModel(BulkUpdateWizardModel, { data: info, promise: p });

            });
        });
    },

    processOrderEmailTempate: function (template, data) {
        console.log('processing order email template');
        console.log(data);

        var res = {
            Subject: template.Subject,
            Body: template.Body
        };

        var exps = [
            {
                exp: /\{\{User.FullName\}\}/g,
                val: data.User.FullName
            },
            {
                exp: /\{\{Contact.FirstName\}\}/g,
                val: data.Contact.FirstName
            },
            {
                exp: /\{\{Job.Reference\}\}/g,
                val: data.Job.Reference
            },
            {
                exp: /\{\{Job.ProjectCustomerPO\}\}/g,
                val: data.Job.CustomerReference
            },
        ];

        exps.forEach(function (e) {

            var val = e.val;

            if (accentUtils.isNull(val)) {
                val = "";
            }

            res.Subject = accentUtils.isEmpty(res.Subject) ? '' : res.Subject.replace(e.exp, val);
            res.Body = accentUtils.isEmpty(res.Body) ? '' : res.Body.replace(e.exp, val);
        });


        return res;
    },

    
    canMarkAsDispatched(job) {
        return !Helper.jobIsClosed(job);
    },


    canChangeCMRequired(line) {

        return !line.SupplyJobCreated && line.DispatchStage != jobLineDispatchStages.Delivered && line.DispatchStage != jobLineDispatchStages.PickedUp && line.DispatchStage != jobLineDispatchStages.Installed;

    },

    markAsDispatched(jobIDs) {

        return new Promise(p => {

            DataService.update("GetDispatchWizardModel", { Jobs: jobIDs }).then(m => {

                showWizard(MoveToDispatchedWizard, true, { model: m }, true).then(function (res) {

                    if (!res.canceled) {

                        DataService.update("MoveToDispatched", m).then(rr => {
                            p(true);
                        });
                    } else {
                        p(false);
                    }
                });

            });


        });
    },

    jobMoveToSupply(jobIDs) {

        


        return new Promise(p => {

            showDialog(<MoveToSupplyDlg jobIDs={jobIDs} />).then(e => {
                if (!e.canceled) {

                    DataService.update("MoveJobsToSupply", {
                        Jobs: e.items
                    }).then(res => p(true));
                    
                    
                } else {
                    p(false);
                }
            });


        });

    },

    alterJobTags(jobIDs, args) {
        return DataService.update("AlterJobTags", {
            Jobs: jobIDs,
            Actions: args
        });
    },

    setCommitmentReason(jobIDs, args) {
        return UpdateCommitReasonDlg.Show(jobIDs.map(i => i.ID));
    },

    setCommissionDate(jobIDs, args) {
        return BulkUpdateCommissionDateDlg.Show(jobIDs.map(i => i.ID));
    },


    invoiceJobs(jobIDs, args) {

        return DataService.update("InvoiceJobs", {
            Jobs: jobIDs,
            Actions: args
        });

    },
    jobReceiptGoods(jobIDs) {

        return DataService.update("ReceiptJobsGoods", {
            Jobs: jobIDs
        });

    },

    jobClose(jobIDs) {

        return DataService.update("CloseJob", {
            Jobs: jobIDs
        });

    },
    jobCancel(jobID) {

        return Helper.cancelJob(jobID);

    },


    canSplitLine(job, line) {

        var jobReadOnly = Helper.canEditJob(job).readOnly;

        var lineHasMultiQty = line.Qty > 1;

        return lineHasMultiQty && (
            (job.Stage === jobStages.Quote && !jobReadOnly)
            || (job.Stage === jobStages.Order && jobReadOnly)
        );

    },

    jobIsClosed(job) {
        return !accentUtils.isNull(job.IsClosed) && job.IsClosed === true || !accentUtils.isNull(job.Status) && job.Status === jobStatus.Closed;
    },
    jobIsCancelled(job) {
        return job.Status === jobStatus.Cancelled;
    },

    canChangeHold(job) {
        return !Helper.jobIsClosed(job) && !Helper.jobIsCancelled(job) && (job.Stage === jobStages.Order || job.Stage === jobStages.Quote);
    },

    getJobLineStatusColor(status) {

        if (status === jobLineStatus.Edit) return "warning";

        if (status === jobLineStatus.OnHold) return "danger";

        return undefined;

    },

    getJobLineStageColor(jobStage) {

        if (jobStage === jobStages.OrderEdit) return "warning";

        return undefined;

    },


    jobIsLost(job) {
        return !accentUtils.isNull(job.IsLost) && job.IsLost === true;
    },


    jobLineFilter(isOrder) {
        return isOrder ? l => Helper.jobLineIsConfirmed(l) : l => !Helper.jobLineIsConfirmed(l);
    },

    jobLineIsConfirmed(line) {
        return line.Stage !== jobLineStages.Unconfirmed && line.Stage !== jobLineStages.Unconfirmed_Sent;
    },


    allowCreateServiceJob() {

        if (!UserService.getAccess().features().FeatureAllowCreateServiceJob()) {
            showOK("application_strings.application.dialogs.requireServiceJobFeatureTitle", "application_strings.application.dialogs.requireServiceJobFeatureMsg", ["application_strings.application.buttons.ok"], false, null, true);
        }

        return UserService.getAccess().features().FeatureAllowCreateServiceJob();

    },


    jobLineRequireReprice(line, includeLocation) {

        var isNew = line.entityAspect.entityState.name === "Added";

        var reprice =
            isNew
            || "Width" in line.entityAspect.originalValues
            || "Drop" in line.entityAspect.originalValues
            || "OrderLineOptionsData" in line.entityAspect.originalValues
            || "WidthMeasurements" in line.entityAspect.originalValues
            || "DropMeasurements" in line.entityAspect.originalValues
            || "Qty" in line.entityAspect.originalValues
            || "Price" in line.entityAspect.originalValues
            || "PriceTax" in line.entityAspect.originalValues
            || "PriceIncTax" in line.entityAspect.originalValues
            || "ProductID" in line.entityAspect.originalValues
            || (includeLocation && "LocationOther" in line.entityAspect.originalValues);

        return reprice;
    },


    jobIsOrder(job) {
        return job.Stage === "stage_job_order" || job.Stage === "stage_job_order_edit";
    },

    jobIsQuote(job) {
        return job.Stage === "stage_job_quote";
    },


    async showUnconfirmMultiLineWarning(line, lines) {

        let ids = [line.ID];

        const isMultiLine = !accentUtils.isNull(line.MultiGroupID);

        if (isMultiLine) {
            const multiLines = from(lines).where(l => l.MultiGroupID === line.MultiGroupID).toArray();

            if (multiLines.length > 1) {

                const res = await showOK("application_strings.application.dialogs.warnMultiLineUnconfirm.title", t("application_strings.application.dialogs.warnMultiLineUnconfirm.msg", { count: multiLines.length, lines: multiLines.map(l => l.JobLineNo).join(", ") }), ["application_strings.application.buttons.unconfirm"], false, null, false, 800);

                if (res !== "application_strings.application.buttons.unconfirm") {
                    return [];
                }

                ids = multiLines.map(l => l.ID);
            }

        }

        return ids;

    },

    async showDeleteMultiLineWarning(line, lines) {

        let ids = [line.ID];

        const isMultiLine = !accentUtils.isNull(line.MultiGroupID);

        if (isMultiLine) {
            const multiLines = from(lines).where(l => l.MultiGroupID === line.MultiGroupID).toArray();

            if (multiLines.length > 1) {

                const res = await showOK("application_strings.application.dialogs.warnMultiLineDelete.title", t("application_strings.application.dialogs.warnMultiLineDelete.msg", { count: multiLines.length, lines: multiLines.map(l => l.JobLineNo).join(", ") }), ["application_strings.application.buttons.delete"], false, null, false, 800);

                if (res !== "application_strings.application.buttons.delete") {
                    return [];
                }

                ids = multiLines.map(l => l.ID);
            }

        }

        return ids;

    },



    async getUnconfirmLineValidationWarning(ids) {

        const warningInfo = await DataService.queryNoTracking("GetUnConfirmLineValidationWarning", undefined, true).getFirstOrDefault({ IDs: ids });

        const showMessage = warningInfo?.HasInvoice || warningInfo?.HasActivities || warningInfo?.HasSupply;

        if (!showMessage) {
            return true;
        }

        const msg = <div>
            {warningInfo?.HasInvoice && <div>{t("application_strings.application.dialogs.unconfirmWarning.invoice")}</div>}
            {warningInfo?.HasSupply && <div>{t("application_strings.application.dialogs.unconfirmWarning.supply")}</div>}
            {warningInfo?.HasActivities && <div>{t("application_strings.application.dialogs.unconfirmWarning.activites")}</div>}
            {(warningInfo?.RemoveActivityCount ?? 0) > 1 && <div>{t("application_strings.application.dialogs.unconfirmWarning.removeActivities", { count: warningInfo.RemoveActivityCount })}</div>}
            {(warningInfo?.RemoveActivityCount ?? 0) === 1 && <div>{t("application_strings.application.dialogs.unconfirmWarning.removeActivity", { count: warningInfo.RemoveActivityCount })}</div>}
            <div style={{paddingTop : "10px"}}>{t("application_strings.application.dialogs.unconfirmWarning.msg")}</div>
        </div>;

        const res = await showOK("application_strings.application.dialogs.unconfirmWarning.title", msg, ["application_strings.application.buttons.unconfirm"], false, undefined, false, 600);


        return res === "application_strings.application.buttons.unconfirm";

    },

    markJobLineAsUnconfirmed(line) {

        line.TagClientUnconfirmed = true;

        

        //if (line.Stage === jobLineStages.CMInProgress || line.Stage === jobLineStages.Confirmed_CM) {
        //    line.Stage = jobLineStages.Unconfirmed_CM;
        //} else if (line.Stage === jobLineStages.CMCompleted || line.Stage === jobLineStages.CMCompleted_Confirmed || line.Stage === jobLineStages.CMCompleted_Reschedule || line.Stage === jobLineStages.CMCompleted_Confirmed_Reschedule) {
        //    line.Stage = jobLineStages.CMCompleted_Unconfirmed;
        //} else {
        //    line.Stage = jobLineStages.Unconfirmed;
        //}

    },



    jobQuoteExpired(job) {

        return (Helper.jobIsQuote(job) && new Date(job.QuoteExpiryDate) < new Date());

    },

    canOpenExpiredQuote(job) {
        return Helper.jobQuoteExpired(job) && UserService.getAccess().features().FeatureAllowOpenExpiredQuotes();

    },
    canOpenLostQuote(job) {
        return Helper.jobIsLost(job) && UserService.getAccess().features().FeatureAllowOpenExpiredQuotes();

    },

    getReadOnlyResult(readOnly, reason, isClosed) {
        return {
            readOnly: readOnly,
            reason: reason,
            isClosed: isClosed
        };
    },

    calculateCreditCardFee(paymentAmount, creditCardType, feeTable) {

        if (accentUtils.isEmpty(paymentAmount) || paymentAmount <= 0) {
            return 0;
        }

        if (accentUtils.isEmpty(feeTable)) {
            return 0;
        }


        if (accentUtils.isEmpty(creditCardType)) return 0;

        const fee = from(feeTable)
            .where(f => f.Text === creditCardType)
            .where(f => paymentAmount >= f.From && (paymentAmount <= f.To || accentUtils.isNull(f.To)))
            .orderBy(f => f.Value)
            .firstOrDefault();


        if (accentUtils.isNull(fee)) return 0;


        return accentUtils.roundNormal(((paymentAmount * fee.Value) / 100), 2).toFixed(2);

    },

    canEditPayment: function (payment) {
        
        var isClosed = payment.Status === "status_payment_finalised" || payment.Status === "status_payment_cancelled";
        var canEdit = payment.Status === "status_payment_draft";

        return Helper.getReadOnlyResult(!canEdit, payment.Status, isClosed);

    },

    canDiscountJob: function (job) {
        return !Helper.canEditJob(job).readOnly;
    },


    canEditJob: function (job, linesToCheckForCM) {
        var date = new Date();

        var isQuote = Helper.jobIsQuote(job);

        if (Helper.jobIsCancelled(job)) {
            return Helper.getReadOnlyResult(true, "job_cancelled", true);
        }



        if (Helper.jobQuoteExpired(job)) {
            return Helper.getReadOnlyResult(true, "quote_expired", true);
        }

        if (Helper.jobIsLost(job)) {
            return Helper.getReadOnlyResult(true, "job_lost", true);
        }


        if (Helper.jobIsClosed(job)) {
            return Helper.getReadOnlyResult(true, "job_closed", true);
        }

        if (isQuote) {
            return Helper.getReadOnlyResult(false, "", false);
        }
        var jobInEditMode = Helper.jobIsInEdit(job);

        if (jobInEditMode) {
            if (Helper.jobIsInCheckMeasure(job)) {

                var canEdit = (UserService.getAccess().features().FeatureAllowPerformCheckMeasure() && Helper.jobIsInCheckMeasureInProgress(job)) || (Helper.jobIsInCheckMeasureAproval(job) && UserService.getAccess().features().FeatureAllowApproveCheckMeasure());

                return Helper.getReadOnlyResult(!canEdit, "", false);
            } else {
                return Helper.getReadOnlyResult(!UserService.getAccess().features().FeatureAllowEditJobs(), "", false);
            }
        }

        return Helper.getReadOnlyResult(true, "", false);


    },

    canEditJobLine: function (line, job) {


        const canEditJob = Helper.canEditJob(job, [line]);

        if (canEditJob.readOnly) {
            return canEditJob;
        }


        var isQuote = Helper.jobIsQuote(job);

        if (line.Status === jobLineStatus.Closed) {
            return Helper.getReadOnlyResult(true, jobLineStatus.Closed, true);
        }

        if (line.Status === jobLineStatus.Cancelled) {
            return Helper.getReadOnlyResult(true, jobLineStatus.Cancelled, true);
        }


        var fitterCMEdit =
            line.Status === jobLineStatus.Open
            && UserService.getAccess().features().FeatureAllowPerformCheckMeasure()
            && (
                Helper.jobLineIsInCheckMeasureInProgress(line)
                || line.Stage === jobLineStages.Unconfirmed
                || line.Stage === jobLineStages.Unconfirmed_Sent
            );


        var approveCMEdit =
            line.Status === jobLineStatus.Open
            && UserService.getAccess().features().FeatureAllowApproveCheckMeasure()
            && (
                Helper.jobLineIsInCheckMeasureAproval(line)
                || Helper.jobLineIsInCheckMeasureInProgress(line) 
                || line.Stage === jobLineStages.Unconfirmed
                || line.Stage === jobLineStages.Unconfirmed_Sent                
            );

        var editEdit = UserService.getAccess().features().FeatureAllowEditJobs()
            && line.Status === jobLineStatus.Edit;


        var canEdit =  isQuote|| editEdit || approveCMEdit || fitterCMEdit;

        return Helper.getReadOnlyResult(!canEdit, line.Stage, false);

    },


    canMarkAsLost: function (job) {

        if (accentUtils.isNull(job.OpportunityID)) return false;

        return !Helper.jobIsLost(job);
    },

    canReceiptGoods: function (job) {

        if (!UserService.getAccess().features().FeatureAllowReceiptGoods()) return false;

        return true;
    },

    canInvoiceJob: function (job) {
        return !Helper.jobIsQuote(job)
    },

    allowSendOrderConfirmation(job) {
        if (accentUtils.isNull(job.Stage)) {
            return true; // only called from job tracking grids
        }

        return Helper.jobIsOrder(job) && !Helper.jobIsClosed(job);
    },

    canMakePayment: function (job) {

        if (accentUtils.isNull(job.Stage)) {
            return true; // only called from job tracking grids
        }

        return Helper.jobIsOrder(job) && !Helper.jobIsClosed(job);
    },

    canMoveToSupply: function (job) {
        //always show as BLINDS-11929
        if (accentUtils.isNull(job.Stage) || job.Stage.indexOf("job_line_") >= 0) {
            return true; // only called from job tracking grids
        }

        return Helper.jobIsOrder(job) && !Helper.jobIsClosed(job);
    },

    canUpdateETA: function (job) {

        return !Helper.jobIsQuote(job);
    },

    jobIsInActiveReadonly: function (job) {
        return !Helper.jobIsQuote(job) && !Helper.jobIsClosed(job) && job.Stage !== "stage_job_order_edit";
    },

    jobRequiresEdit: function (job) {
        return Helper.jobIsInActiveReadonly(job) && UserService.getAccess().features().FeatureAllowEditJobs();
    },

    jobRequiresFinishEdit: function (job) {

        return Helper.jobIsInEdit(job) && UserService.getAccess().features().FeatureAllowEditJobs() && accentUtils.isEmpty(job.CheckMeasureStatus);
    },

    jobIsInEdit: function (job) {
        return !Helper.jobIsQuote(job) && job.Stage === "stage_job_order_edit";
    },

    canBookService: function (job) {
        return true;
    },

    canBookCM: function (job) {
        if (job.Stage === jobStages.OrderEdit) return false;
        
        return !Helper.jobIsQuote(job);
    },

    canBookInst: function (job) {
        return !Helper.jobIsQuote(job);
    },

    canEditQuoteExpiry: function (job) {
        return true;
    },


    canSendQuote: function (job) {
        return UserService.getAccess().features().FeatureAllowQuoting()
            && !Helper.jobIsClosed(job)
            && UserService.getAccess().modules().RTL_Quotes()
            && job.JobLines.length > 0;
    },
    canPreviewQuote: function (job, accentUserModel) {
        return UserService.getAccess().features().FeatureAllowQuoting() && UserService.getAccess().modules().RTL_Quotes();
    },

    canReOpenJob: function (job) {
        return Helper.jobIsOrder(job) && Helper.jobIsClosed(job) && !Helper.jobIsCancelled(job);
    },
    canReQuote: function (job) {

        return true;
    },
    canDiscount: function (job) {
        return UserService.getAccess().features().FeatureAllowDiscounting();
    },
    canConfirmQuote: function (job) {
        return from(job.JobLines).any(l => !l.IsConfirmed) && !Helper.jobIsClosed(job) && !Helper.jobQuoteExpired(job);
    },
    emailQuote: function (id, email) {
        return DataService.updateAsFormData("EmailQuote", { ...email, ID: id });
    },


    validateQuoteCustomerReady: function (jobID, hasPriceWarn, requireDiscApproval) {
        console.log('validate quote ready');
        if (hasPriceWarn) {
            if (!UserService.getAccess().features().FeatureAllowDiscounting()) {
                return Helper.showNoDiscountConfirm(jobID);
            } else {
                return Helper.showCheckDiscountsDlg(jobID);
            }
        }

        if (requireDiscApproval) {
            if (!UserService.getAccess().features().FeatureAllowDiscounting()) {
                return Helper.showNoDiscountConfirm(jobID);
            } else {
                return Helper.showDiscountApprovalRequiredDlg(jobID);
            }
        }

        return Promise.resolve(true);
    },

    showDiscountApprovalRequiredDlg: function (jobID) {

        return new Promise(p => {

            showOK("application_strings.application.dialogs.requireMarginApprovalTitle", "application_strings.application.dialogs.requireMarginApprovalMsg", ["application_strings.application.buttons.ok"], false, null, false, 600).then(res => {

                p(false);

            });

        });


    },



    showCheckDiscountsDlg: function (jobID) {

        return new Promise(p => {

            showOK("application_strings.application.dialogs.checkDiscountsTitle", "application_strings.application.dialogs.checkDiscountsMsg", ["application_strings.application.buttons.showDiscounts"]).then(res => {


                if (res === "application_strings.application.buttons.showDiscounts") {

                    goTo("/JobDiscount/" + jobID);
                }

                p(false);

            });

        });


    },
    showNoDiscountConfirm: function (jobID) {
        return new Promise(p => {

            showOK("application_strings.application.dialogs.noDiscountPermission", "application_strings.application.dialogs.noDiscountPermissionMsg", []).then(res => {

                p(false);

            });

        });
    },
    sendTestEmail: function () {

        new Promise(p => {
            DataService.update("SendTestEmail", {}).then(res => {
                Helper.processEmailSendResult(p, res);
            });
        }).then(r => {
            if (!accentUtils.isNull(r.error)) {
                showOK(r.error.title, r.error.msg, ["application_strings.application.buttons.close"], true);
            } else {

                if (r.retry) {
                    Helper.sendTestEmail();
                } else {
                    showOK("application_strings.views.sendEmailDialog.titleResult", "application_strings.views.sendEmailDialog.sendEmailSuccess", ["application_strings.application.buttons.close"], false, null, true);
                }


            }

        });



    },
    processEmailSendResult: function processEmailSendResult(promise, sendResult) {
        console.log('processing send result');
        console.log(sendResult);
        if (sendResult.IsSent) {
            promise({ error: null, msg: "application_strings.views.sendEmailDialog.sendEmailSuccess" });
        } else {

            if (sendResult.ConfigurationRequired) {
                promise({ error: { title: "application_strings.views.sendEmailDialog.titleResult", msg: t("application_strings.views.sendEmailDialog.configurationRequired") } });
            } else if (sendResult.CredentialRequired) {

                var newUsername = null;
                var newPassword = null;

                const user = UserService.getUser();

                showOK("application_strings.views.sendEmailDialog.emailAccountDetails", React.createElement(EmailAccountDetailsCtrl, {
                    email: user.Email, onUsernameChange: function onUsernameChange(un) {
                        return newUsername = un;
                    }, onPasswordChange: function onPasswordChange(pw) {
                        return newPassword = pw;
                    }
                }), ["application_strings.application.buttons.ok"]).then(function (okRes) {
                    if (okRes === "application_strings.application.buttons.ok") {

                        DataService.update("SaveSMPTUserAccount", { Username: newUsername, Password: newPassword, ClientSchema: '' }).then(function (saveRes) {
                            promise({ retry: true, error: null, msg: "" });
                        });
                    } else {

                        promise({ error: { title: "application_strings.views.sendEmailDialog.titleResult", msg: "application_strings.views.sendEmailDialog.requireAccountDetails" } });
                    }
                });
            } else {
                promise({ error: { title: "application_strings.views.sendEmailDialog.titleResult", msg: sendResult.Response } });
            }

        }
    },
    getMessages: function (businessUnitId, status, start, end, offset, limit) {
        return DataService.update("GetMessages", { BusinessUnitID: businessUnitId, Status: status, Start: start, End: end, Offset: offset, Limit: limit });
    },
    sendTestSms: function (businessUnitId, mobileNumber) {

        new Promise(p => {

            DataService.update("SendTestSms", { BusinessUnitID: businessUnitId, MobileNumber: mobileNumber }).then(res => {
                Helper.processSmsSendResult(p, res);
            });
        }).then(r => {
            if (!accentUtils.isNull(r.error)) {
                showOK(r.error.title, r.error.msg, ["application_strings.application.buttons.close"], true, null, false, 600);
            } else {

                if (r.retry) {
                    Helper.sendTestSms();
                } else {
                    showOK("application_strings.views.sendSmsDialog.titleResult", "application_strings.views.sendSmsDialog.sendSmsSuccess", ["application_strings.application.buttons.close"], false, null, true, 600);
                }


            }

        });

    },
    sendSms: function (mobileNumber, body, contactID, jobID, opportunityID, activityID, templateID, deliveryReceipt, invoiceID) {


        return new Promise(finalResolve => {


            new Promise(p => {

                DataService.update("SendSms", {
                    MobileNumber: mobileNumber,
                    Body: body,
                    ContactID: contactID,
                    JobID: jobID,
                    OpportunityID: opportunityID,
                    ActivityID: activityID,
                    TemplateID: templateID,
                    DeliveryReceipt: deliveryReceipt,
                    InvoiceID: invoiceID
                }).then(res => {
                    Helper.processSmsSendResult(p, res);
                });
            }).then(r => {
                if (!accentUtils.isNull(r.error)) {
                    showOK(r.error.title, t(r.error.msg), ["application_strings.application.buttons.close"], true, null, false, 600).then(() => finalResolve());
                } else {

                    if (r.retry) {
                        Helper.sendSms(mobileNumber, body, contactID, jobID, opportunityID, activityID, templateID, deliveryReceipt, invoiceID).then(() => finalResolve());
                    } else {
                        showOK("application_strings.views.sendSmsDialog.titleResult", "application_strings.views.sendSmsDialog.sendSuccess", ["application_strings.application.buttons.close"], false, null, true, 600).then(() => finalResolve());
                    }


                }

            });


        });

        

    },

    sendRepresentativeSms: function (mobileNumber, body, contactID, jobID, opportunityID, activityID, templateID, deliveryReceipt, invoiceID) {

        new Promise(p => {
            DataService.update(
                "SendRepresentativeSms",
                {
                    MobileNumber: mobileNumber,
                    Body: body,
                    ContactID: contactID,
                    JobID: jobID,
                    OpportunityID: opportunityID,
                    ActivityID: activityID,
                    TemplateID: templateID,
                    DeliveryReceipt: deliveryReceipt,
                    InvoiceID: invoiceID
                }).then(res => {
                    Helper.processSmsSendResult(p, res);
                });
        }).then(r => {
            if (!accentUtils.isNull(r.error)) {
                showOK(r.error.title, r.error.msg, ["application_strings.application.buttons.close"], true, null, true, 600);
            } else {

                if (r.retry) {
                    Helper.sendRepresentativeSms(mobileNumber, body, contactID, jobID, opportunityID, activityID, templateID, deliveryReceipt, invoiceID);
                } else {
                    showOK("application_strings.views.sendSmsDialog.titleResult", "application_strings.views.sendSmsDialog.sendSuccess", ["application_strings.application.buttons.close"], false, null, true, 600);
                }


            }

        });
    },
    sendWebLeadSms: function (mobileNumber, body, leadID, deliveryReceipt) {

        new Promise(p => {
            DataService.update("SendWebLeadSms", { MobileNumber: mobileNumber, Body: body, ContactID: leadID, DeliveryReceipt: deliveryReceipt }).then(res => {
                Helper.processSmsSendResult(p, res);
            });
        }).then(r => {
            if (!accentUtils.isNull(r.error)) {
                showOK(r.error.title, r.error.msg, ["application_strings.application.buttons.close"], true, null, true, 600);
            } else {

                if (r.retry) {
                    Helper.sendSms(mobileNumber, body, leadID, deliveryReceipt);
                } else {
                    showOK("application_strings.views.sendSmsDialog.titleResult", "application_strings.views.sendSmsDialog.sendSuccess", ["application_strings.application.buttons.close"], false, null, true, 600);
                }


            }

        });

    },

    saveSmsConfig: function (businessUnitId, host, port, username, password) {

        new Promise(p => {

            DataService.update("SaveSmsConfig", {
                BusinessUnitID: businessUnitId, Host: "",
                Port: 0, Username: username, Password: password
            }).then(res => {
                Helper.processSaveSmsConfigResult(p, res);
            });
        }).then(r => {
            if (!accentUtils.isNull(r.error)) {
                showOK(r.error.title, r.error.msg, ["application_strings.application.buttons.close"], true);
            }

        });

    },
    saveEmailConfig: function (emailConfigJson) {

        var emailConfig = JSON.parse(emailConfigJson);
        console.log('parsed email config');
        console.log(emailConfig);
        var host = emailConfig.SMTP ? emailConfig.SMTP.Server : '';
        var port = emailConfig.SMTP ? emailConfig.SMTP.Port : 0;
        var username = emailConfig.SMTP ? emailConfig.SMTP.Username : '';
        var password = emailConfig.SMTP ? emailConfig.SMTP.Password : '';
        var fromAddress = emailConfig.SMTP ? emailConfig.SMTP.GeneralFrom : '';
        var authenticationEnabled = emailConfig.SMTP ? emailConfig.SMTP.UseAuthentication : false;
        var sslEnabled = emailConfig.SMTP ? emailConfig.SMTP.UseSSL : false;
        var insyteWebEnabled = emailConfig.Provider === 1;
        var singleFromEnabled = emailConfig.SMTP ? emailConfig.SMTP.SingleAccountFrom === 1 : false;
        var individualAccountsEnabled = emailConfig.SMTP ? emailConfig.SMTP.AccountType === 2 : false;
        new Promise(p => {

            DataService.update("SaveEmailConfig", {
                BusinessUnitID: emailConfig.BusinessUnitID,
                Host: host,
                Port: port,
                Username: username,
                Password: password,
                FromAddress: fromAddress,
                AuthenticationEnabled: authenticationEnabled,
                SSLEnabled: sslEnabled,
                InsyteWebEnabled: insyteWebEnabled,
                IndividualAccountsEnabled: individualAccountsEnabled,
                SingleFromEnabled: singleFromEnabled

            }).then(res => {
                Helper.processSaveEmailConfigResult(p, res);
            });
        }).then(r => {
            if (!accentUtils.isNull(r.error)) {
                showOK(r.error.title, r.error.msg, ["application_strings.application.buttons.close"], true);
            }

        });



    },
    processSmsSendResult: function (promise, sendResult) {

        if (sendResult.IsSent) {
            promise({ error: null, msg: "application_strings.views.sendSmsDialog.sendSmsSuccess" });
        } else {
            promise({ error: { title: "application_strings.views.sendSmsDialog.titleResult", msg: sendResult.Response } });
        }


    },
    processSaveSmsConfigResult: function (promise, sendResult) {

        if (sendResult.IsSent) {
            promise({ error: null, msg: "application_strings.views.sendSmsDialog.configSaved" });
        } else {
            promise({ error: { title: "application_strings.views.sendSmsDialog.configSaved", msg: sendResult.Response } });
        }


    },

    processSaveEmailConfigResult: function (promise, sendResult) {

        if (sendResult.IsSent) {
            promise({ error: null, msg: "application_strings.views.sendSmsDialog.emailConfigSaved" });
        } else {
            promise({ error: { title: "application_strings.views.sendSmsDialog.emailConfigSaved", msg: sendResult.Response } });
        }


    },

    canBulkChange: function (job) {
        return !Helper.canEditJob(job).readOnly;
    },

    
    showEmailQuoteWizard: function (quoteID, signature) {


        return new Promise(function (p) {

            query_QuoteEmailDetails.getFirstOrDefault({ id: quoteID }).then(function (info) {

                var quoteDoc = info.Attachments[0];

                if (signature) {
                    quoteDoc.postData = { "Signature": signature};
                }
                
                
                quoteDoc.HeaderItem = <QuoteParamsCtrl model={info} type="Quote" />;
                

                showDialog(<EmailDlg
                    title="application_strings.application.general.emailQuote"
                    emailTemplateAppSettingsField="DefaultQuoteEmailTemplate"
                    templates={info.Templates}
                    to={info.To}
                    getAIContext={async e => ({ ...e, EmailType: apiContextEmailTypes.Quote, RecordID: quoteID }) }
                    attachments={info.Attachments}
                    onSend={e => {
                        return new Promise(resolveSent => {

                            const quoteDocID = `QUOTE:${quoteID}`;
                            const model = mapToBaseEmailViewModel(e, info.ID, info.Data.Contact.FullName, { Signature : signature }, quoteDocID);
                            Helper.emailQuote(quoteID, model).then(sr => Helper.processEmailSendResult(resolveSent, sr));
                        });
                    }}
                />).then(r => {
                        p();
                });



            });
        });
    },

    showEmailOrderWizard: function (orderID) {

        return new Promise(function (p) {

            query_OrderEmailDetails.getFirstOrDefault({ id: orderID }).then(function (info) {

                var quoteDoc = info.Attachments[0];



                quoteDoc.HeaderItem = <QuoteParamsCtrl model={info} type="OrderConfirmation" />;


                showDialog(<EmailDlg
                    title="application_strings.application.general.emailOrderConfirmation"
                    emailTemplateAppSettingsField="DefaultOrderConfirmationEmailTemplate"
                    templates={info.Templates}
                    to={info.To}
                    attachments={info.Attachments}
                    onSend={e => {
                        return new Promise(resolveSent => {

                            const quoteDocID = `ORDER:${orderID}`;

                            const model = mapToBaseEmailViewModel(e, info.ID, info.Data.Contact.FullName, {}, quoteDocID);

                            Helper.emailOrderConfirmation(orderID, model).then(sr => Helper.processEmailSendResult(resolveSent, sr));
                        });
                    }}
                />).then(r => {
                    p();
                });



            });
        });


    },


    emailOrderConfirmation: function (id, email) {
        return DataService.updateAsFormData("EmailOrderConfirmation", { ...email, ID: id })        
    },

    lostOpportunity: function (model) {
        return DataService.update("LostOpportunity", model);
    },

    canCancelJob: function (job) {
        if (Helper.jobIsQuote(job) && job.JobLines.length === 0) {
            return true;
        }
        return !accentUtils.isEmpty(job.Status) && job.Status != jobStatus.Cancelled;
    },
    canUnCancelJob: function (job) {
        return  job.Status === jobStatus.Cancelled;
    },

    reviewPurchaseOrder: function (po) {

    },
    confirmQuote: async function (model, captureSignature) {


        const signatureResult = captureSignature ? await showDialog(<SignatureDlg />) : null;


        if (signatureResult?.canceled) return null;


        const waitDone = showWait();

        const res = await DataService.update("ConfirmQuote", model);

        if (res.Failed) {

            await waitDone();
            await showOK("application_strings.application.dialogs.confirmQuoteFailedTitle", replaceI18nKeysInHtmlString(res.Message), ["application_strings.application.buttons.ok"]);
            return (null);
        } 

        await waitDone();

        if (!accentUtils.isNull(signatureResult)) {

            
            await Helper.showEmailQuoteWizard(model.ID, signatureResult.signature);
        }

        
        return (res.OrderID);
        


    },

    detachMultiProductLine: async function (id, lineID) {

        
        const result = await showOK("application_strings.application.dialogs.detachMultiProductDlg.title", "application_strings.application.dialogs.detachMultiProductDlg.msg", ["application_strings.application.buttons.detach"], false, null, false, 600);

        if (result !== "application_strings.application.buttons.detach") return;

        return await DataService.update("JobLine_DetachMultiProductLine", { ID: lineID} , true);
    },

    cancelPayment: function (id) {

        return new Promise(function (p) {

            DataService.update("CancelPayment", { ID: id }).then(res => p(res));

        });

    },

    cancelJob: function (id) {
        return DataService.update("CancelJob", { ID: id });
    },
    undoCancelJob: function (id) {
        return DataService.update("UndoCancelJob", { ID: id });
    },
    reOpenJob: function (id) {
        return DataService.update("ReOpenJob", { ID: id });
    },
    reQuote: async function (model) {

        var done = showWait();

        var res = await DataService.update("ReQuoteJob", model);


        done();

        return res;

    },
    allocatePayment: function (model) {
        return DataService.update("AllocatePayment", model);
    },

    setJobSalePrice: function (model) {
        return DataService.update("SetJobSalePrice", model);
    },

    priceJob: function (model) {
        return DataService.update("PriceJob", model);
    },


    repriceJobLine: function (id) {
        return DataService.update("RePriceJobLine", { ID: id});
    },

    priceJobLine: function (ids, jobID, isQuote) {
        return DataService.update("PriceJobLine", { ID: ids, JobID: jobID, IsQuote : isQuote });
    },

    holdJob: async function (id) {


        const model = await showDialog(<JobOnHoldDlg id={id} setOnHold />);

        if (model.canceled) return;

        const result = await DataService.update("Job_Hold", model, true);


        if (result.HasError) {
            await showDialog(<JobOnHoldErrorDlg result={ result} />);
            return false;
        }

        return true;

    },
    unHoldJob: async function (id) {

        const model = await showDialog(<JobOnHoldDlg id={id} />);

        if (model.canceled) return;

        return await DataService.update("Job_UnHold", model, true);
    },

};
